<template>
  <div class="hold-transition login-page bg-login view-user-login">
    <div class="login-box">
      <div class="card">
        <div class="card-body login-card-body">
          <div class="row">
            <div class="col-12 text-center mb-3">
              <img
                class="logo-login-img"
                src="@/assets/img/dentcloud-logo-darkblue.png"
              />
              <h3 class="logo-caption mt-2">
                <span class="">D</span>ent<span class="">C</span>loud
              </h3>
            </div>
          </div>
          <div v-if="isLogin">
            <div v-if="!isError && !isMissingAllowedConsent">
              <div v-if="missingAllowedScopes.length === 0">
                <div class="d-flex justify-content-center mb-1">
                  <b-avatar
                    :variant="user.contentId ? 'white' : 'info'"
                    :text="abbreviation"
                    alt="User Image"
                    size="4rem"
                    :src="user.profile_url"
                  ></b-avatar>
                </div>
                <h4 class="text-muted text-center">
                  {{ `${user.first_name_th} ${user.last_name_en}` }}
                </h4>

                <div class="col-12">
                  <button
                    class="btn btn-success btn-block btn-sm"
                    @click="requestAuthorizationCode"
                  >
                    เข้าสู่ระบบด้วย DentCloud
                  </button>
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="signInWithOtherAccount"
                  >
                    ใช้บัญชีอื่น
                  </button>
                </div>
              </div>
              <div v-else>
                <div
                  class="d-flex justify-content-center mb-1 align-items-center"
                >
                  <b-avatar
                    :variant="user.contentId ? 'white' : 'info'"
                    :text="abbreviation"
                    alt="User Image"
                    size="2rem"
                    :src="user.profile_url"
                  ></b-avatar>
                  <p class="text-muted text-center m-0 ml-1">
                    {{ `${user.email}` }}
                  </p>
                </div>

                <h5 class="text-muted text-center mt-4">
                  แอพพลิเคชั่น
                  <b class="text-primary">{{ this.clientName }}</b>
                  ต้องการเข้าถึง:
                </h5>
                <ul>
                  <li
                    v-for="(scope, index) in missingAllowedScopes"
                    :key="index"
                  >
                    <b>{{ scope }}</b> {{ scopeDescription[scope].description }}
                  </li>
                </ul>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <button
                        class="btn btn-primary"
                        @click="() => giveConsentToAccessUserResrource(false)"
                      >
                        ยกเลิก
                      </button>
                    </div>
                    <div class="col text-right">
                      <button
                        class="btn btn-success"
                        @click="() => giveConsentToAccessUserResrource(true)"
                      >
                        ยินยอม
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isMissingAllowedConsent">
              <h5 class="text-muted text-center">
                คุณไม่อณุญาตให้แอพพลิเคชั่น
                <b class="text-primary">{{ this.clientName }}</b>
                ทำการเข้าถึง:
              </h5>
              <ul>
                <li v-for="(scope, index) in missingAllowedScopes" :key="index">
                  <b>{{ scope }}</b> {{ scopeDescription[scope].description }}
                </li>
              </ul>
              <div class="row">
                <div class="col-12">
                  <button
                    class="btn btn-success btn-block btn-sm"
                    @click="
                      () =>
                        this.redirectToURI(
                          `missing_consent`,
                          `missing user consent`
                        )
                    "
                  >
                    ย้อนกลับ
                  </button>
                </div>
              </div>
            </div>

            <div v-else-if="isError">
              <h5 class="text-muted text-center">
                เกิดข้อผิดพลาดในระบบ
              </h5>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12">
                <div
                  class="alert alert-warning alert-dismissible fade show"
                  role="alert"
                  v-if="statusLogin"
                >
                  กรุณาตรวจสอบ อีเมลล์ หรือ รหัสผ่าน ให้ถูกต้อง
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="setStatusLogin"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <b-form @submit.prevent="authenticate">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="email">อีเมลล์</label>
                    <div class="input-group">
                      <input
                        id="login-input-login-view"
                        class="form-control form-control-sm"
                        v-model.trim="loginForm.username"
                        required
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-user"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>รหัสผ่าน</label>
                    <div class="input-group">
                      <input
                        id="password-input-login-view"
                        type="password"
                        class="form-control form-control-sm"
                        v-model="loginForm.password"
                        required
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button
                    type="submit"
                    class="btn btn-success btn-block btn-sm"
                  >
                    เข้าสู่ระบบด้วย DentCloud
                  </button>
                </div>
              </div>
            </b-form>
            <div class="row">
              <div class="col-12">
                <span class="mb-0 mt-3 ml-auto">
                  <a href="/user/forgotpassword" class="text-center"
                    >ลืมรหัสผ่าน?</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import axios from "axios";

const HOST = process.env.VUE_APP_OAUTH_BASE_URL;

export default {
  name: "Login",
  components: {
    Loading,
    Dialog
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      isMissingAllowedConsent: false,
      loginForm: {
        username: "",
        password: "",
        rememberMe: false
      },

      statusLogin: false,
      query: {},
      isLogin: false,
      user: {},
      client_id: "",
      scope: "",
      redirect_uri: "",
      response_type: "",
      code: "",
      clientDetail: null,
      scopeDescription: {
        profile: {
          description: "เข้าถึงข้อมูลโปรไฟล์บัญชี DentCloud ของคุณ"
        }
      },
      missingAllowedScopes: []
    };
  },
  created() {},
  async mounted() {
    try {
      this.query = this.$route.query;
      const { client_id, scope, redirect_uri, response_type } = this.query;
      this.client_id = client_id;
      this.scope = scope;
      this.redirect_uri = redirect_uri;
      this.response_type = response_type;

      await this.getUserProfile();
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    ...mapGetters({
      getLoginApiStatus: "moduleAuth/getLoginApiStatus",
      viewFile: "moduleContent/viewFile"
    }),
    abbreviation() {
      if (this.user?.firstNameEn || this.user?.lastNameEn) {
        var firstName = "";
        var lastName = "";
        if (this.user?.firstNameEn) {
          firstName = this.user.firstNameEn.charAt(0);
        }
        if (this.user?.lastNameEn) {
          lastName = this.user.lastNameEn.charAt(0);
        }
        return `${firstName}${lastName}`;
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      login: "moduleUser/login",
      actionLoginApi: "moduleAuth/loginApi"
    }),

    /**
     *
     * @param {string} error
     * @param {string} errorDesc
     */
    redirectToURI(error, errorDesc) {
      const url = new URL(this.redirect_uri);
      url.search = new URLSearchParams({
        error_desc: errorDesc,
        error: error
      });
      window.location.replace(url);
    },

    /**
     *
     * @param {boolean} allow
     */
    async giveConsentToAccessUserResrource(allow) {
      this.isLoading = true;
      try {
        const response = await axios.post(
          `${HOST}/o/oauth2/consent`,
          {
            allow
          },
          {
            params: {
              client_id: this.client_id
            }
          }
        );
        switch (response.status) {
          case 200:
            this.missingAllowedScopes = [];
            break;
          default:
            this.isMissingAllowedConsent = true;
            break;
        }
      } catch (error) {
        this.showDialogToast("danger", "ระบบขัดข้อง ขออภัยในความไม่สะดวก");
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },

    async getCurrentClientDetail() {
      try {
        const response = await axios.get(`${HOST}/o/oauth2/client`, {
          params: {
            client_id: this.client_id
          }
        });
        this.clientDetail = response.data;
        this.clientName = response.data.client_name;
      } catch (error) {
        this.showDialogToast("danger", "ระบบข้อข้อง ขออภัยในความไม่สะดวก");
        this.isError = true;
      }
    },

    async getUserResourcesAccessConsent() {
      try {
        const response = await axios.get(`${HOST}/o/oauth2/consent`, {
          params: {
            client_id: this.client_id
          }
        });
        const { data } = response;
        switch (response.status) {
          case 200:
            this.missingAllowedScopes = data.scope;
            return;
          default:
            return;
        }
      } catch (error) {
        this.showDialogToast("danger", "เกิดข้อผิดพลาดในระบบ");
        this.isError = true;
      }
    },

    async getUserProfile() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${HOST}/o/account`);
        if (response.status !== 200) {
          return;
        }

        await Promise.all([
          this.getUserResourcesAccessConsent(),
          this.getCurrentClientDetail()
        ]);

        this.user = response.data;
        this.isLogin = true;
      } catch (error) {
        // do nothing
      } finally {
        this.isLoading = false;
      }
    },

    async authenticate() {
      this.isLoading = true;
      try {
        const response = await axios.post(`${HOST}/o/authenticate`, {
          username: this.loginForm.username,
          password: this.loginForm.password
        });

        await this.getUserProfile();
      } catch (error) {
        if (error.response.status !== 201) {
          this.showDialogToast("danger", "อีเมล หรือ รหัสผ่านไม่ถูกต้อง");
        }
      } finally {
        this.isLoading = false;
      }
    },

    signInWithOtherAccount() {
      this.isLogin = false;
      this.loginForm = {
        username: "",
        password: "",
        rememberMe: false
      };
    },

    async requestAuthorizationCode() {
      this.isLoading = true;
      try {
        const { client_id, scope, redirect_uri, response_type } = this.query;
        const response = await axios.get(
          `${HOST}/o/oauth2/authorization-code`,
          {
            params: {
              client_id,
              scope,
              redirect_uri,
              response_type
            }
          }
        );
        const { code } = response.data;
        this.code = code;

        this.redirectWithAuthorizationCode();

        return;
      } catch (err) {
        this.showDialogToast("danger", err.message);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    setStatusLogin() {
      this.statusLogin = false;
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    redirectWithAuthorizationCode() {
      const url = new URL(this.query.redirect_uri);
      url.search = new URLSearchParams({
        grant_type: "code",
        code: this.code
      });
      window.location.replace(url.toString());
    }
  }
};
</script>

<style>
.logo-caption {
  font-family: "Noto Sans Thai";
  font-size: 32px;
  font-weight: 900;
  color: #1d5d8e;
}
.logo-login-img {
  width: 50%;
}
</style>

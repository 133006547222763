<template>
  <aside
    class="main-sidebar sidebar-dark-primary elevation-4 component-menubar-sidebar"
  >
    <router-link :to="{ name: 'Clinic' }" class="brand-link">
      <img
        src="@/assets/img/logo-dentcloud-white.png"
        alt="Pastel Logo"
        class="brand-image"
        style="margin-left: 0.6rem"
      />

      <span class="brand-text font-weight-light">DentCloud</span>
    </router-link>
    <div class="sidebar">
      <nav class="mt-2">
        <ul
          v-if="hasClinicBranchUrl()"
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
        >
          <li class="nav-item" v-if="isAccessMenu(['DOCTOR'])">
            <router-link :to="{ name: 'Dashboard' }" class="nav-link">
              <i class="nav-icon fas fa-user-md"></i>

              <p>ปฏิทินแพทย์</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAccessMenu(['ASSISTANT'])">
            <router-link
              :to="{ name: 'DoctorsTreatingQueue' }"
              class="nav-link"
            >
              <i class="nav-icon far fa-calendar-plus"></i>

              <p>ปฏิทินแพทย์ทุกท่าน</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isAccessMenu(['ASSISTANT', 'COUNTER', 'MANAGER', 'HEADCOUNTER'])
            "
          >
            <router-link :to="{ name: 'QueueManage' }" class="nav-link">
              <i class="nav-icon far fa-calendar-alt"></i>
              <p>ปฏิทินนัดหมาย</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              isAccessMenu(['ASSISTANT', 'COUNTER', 'MANAGER', 'HEADCOUNTER'])
            "
          >
            <router-link :to="{ name: 'QueueList' }" class="nav-link">
              <i class="nav-icon far fa-calendar-minus"></i>
              <p>รายการนัดหมาย</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="isAccessMenu(['MANAGER', 'COUNTER', 'HEADCOUNTER'])"
          >
            <router-link :to="{ name: 'UserManage' }" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>จัดการผู้ใช้</p>
            </router-link>
          </li>
          <li
            class="nav-item has-treeview"
            v-if="isAccessMenu(['COUNTER', 'MANAGER', 'DOCTOR', 'HEADCOUNTER'])"
          >
            <a
              href="#"
              class="nav-link"
              :class="{
                active: checkActiveMenu([
                  'packagemanage',
                  'aptlistmanage',
                  'txlistmanage',
                  'dxlistmanage',
                  'itemslistmanage',
                ]),
              }"
            >
              <i class="nav-icon fas fa-list"></i>
              <p>ลิสต์/แพ็กเกจจัดฟัน <i class="right fas fa-angle-left"></i></p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item offset-1">
                <router-link :to="{ name: 'PackageManage' }" class="nav-link">
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>แพ็กเกจจัดฟัน</p>
                </router-link>
              </li>
              <li class="nav-item offset-1">
                <router-link :to="{ name: 'AptListManage' }" class="nav-link"
                  ><span></span>
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>ลิสต์นัดหมาย</p>
                </router-link>
              </li>
              <li class="nav-item offset-1">
                <router-link :to="{ name: 'TxListManage' }" class="nav-link"
                  ><span></span>
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>ลิสต์การรักษา</p>
                </router-link>
              </li>

              <li class="nav-item offset-1">
                <router-link :to="{ name: 'DxListManage' }" class="nav-link"
                  ><span></span>
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>ลิสต์การวินิจฉัย</p>
                </router-link>
              </li>
              <li class="nav-item offset-1">
                <router-link :to="{ name: 'ItemsListManage' }" class="nav-link"
                  ><span></span>
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>ลิสต์ยา และสินค้า</p>
                </router-link>
              </li>
              <li class="nav-item offset-1">
                <router-link :to="{ name: 'LabProductManage' }" class="nav-link"
                  ><span></span>
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>ลิสต์แลป</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            v-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
          >
            <router-link :to="{ name: 'ReceiptManage' }" class="nav-link">
              <i class="nav-icon fas fa-receipt"></i>
              <p>จัดการใบเสร็จ</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
          >
            <router-link :to="{ name: 'LabExpense' }" class="nav-link">
              <i class="nav-icon fas fa-file-invoice-dollar"></i>
              <p>จัดการค่าแลป</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isAccessMenu(['MANAGER', 'HEADCOUNTER']) &&
              getBranchInfo.fileStoreExpiration
            "
          >
            <router-link :to="{ name: 'FileManage' }" class="nav-link">
              <i class="nav-icon fas fa-file"></i>
              <p>จัดการไฟล์</p>
            </router-link>
          </li>
          <li
            class="nav-item has-treeview"
            v-if="isAccessMenu(['COUNTER', 'MANAGER', 'DOCTOR', 'HEADCOUNTER'])"
          >
            <a
              href="#"
              class="nav-link"
              :class="{
                active: checkActiveMenu(['personalincome', 'branchincome']),
              }"
            >
              <i class="nav-icon fas fa-chart-bar"></i>
              <p>รายงาน <i class="right fas fa-angle-left"></i></p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item offset-1"
                v-if="
                  isAccessMenu(['COUNTER', 'MANAGER', 'DOCTOR', 'HEADCOUNTER'])
                "
              >
                <router-link :to="{ name: 'PersonalIncome' }" class="nav-link">
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>รายได้แพทย์</p>
                </router-link>
              </li>
              <li
                class="nav-item offset-1"
                v-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
              >
                <router-link :to="{ name: 'BranchIncome' }" class="nav-link"
                  ><span></span>
                  <i class="fas fa-circle nav-icon sm-icon"></i>
                  <p>รายงานสาขา</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-if="isAccessMenu(['MANAGER'])">
            <router-link :to="{ name: 'Setting' }" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>ตั้งค่า</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";

export default {
  name: "Sidebar",
  data() {
    return {
      treeViewStarted: false,
      authId: 0,
    };
  },
  computed: {
    ...mapGetters({
      authorization: "moduleUser/getAuthorization",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getBranchInfo: "moduleUser/getBranchInfo",
    }),
  },
  created() {
    this.authId = this.authorization.authId;
  },
  methods: {
    hasClinicBranchUrl() {
      const enabled = !!(
        this.$route.params.clinicUrl && this.$route.params.branchUrl
      );
      if (enabled && !this.treeViewStarted) {
        this.startTreeview();
        this.treeViewStarted = true; //check ว่า init treeviw รอบเดียว ถ้า init หลายๆรอบจะไม่สามารถ collapse ได้
      }
      if (!enabled) {
        this.treeViewStarted = false;
      }
      return enabled;
    },
    startTreeview() {
      this.$nextTick(() => {
        // TODO : We should change to use the treeview component to support vue.
        // Doc Treeview: https://adminlte.io/docs/3.1//javascript/treeview.html
        $('ul[data-widget="treeview"]').Treeview("init");
      });
    },
    navigateBack() {
      window.history.back();
    },
    checkActiveMenu(names) {
      if (Array.isArray(names)) {
        var active = false;
        for (var i = 0; i < names.length; i++) {
          if (names[i] == this.$route.name.toLowerCase()) {
            active = true;
            break;
          }
        }
        return active;
      } else {
        return names == this.$route.name.toLowerCase();
      }
    },
  },
};
</script>

<style scoped>
.sm-icon {
  font-size: 0.8rem !important;
}
/*.component-menubar-sidebar {
  z-index: 1070 !important;
}*/
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hold-transition view-user-register",staticStyle:{"background":"#e9ecef","overflow-x":"hidden"}},[_c('div',{staticClass:"row justify-content-center align-items-center",staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"row mx-3"},[_c('div',{staticClass:"col-12 mt-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body login-card-body text-left"},[(_vm.registerStatus == false)?[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("สร้างบัญชีใหม่!")]),_c('p',[_vm._v(" กรุณาลงข้อมูลตามความเป็นจริง ข้อมูลจะถูกใช้ในการออกเอกสาร และรายงาน เช่น การออกใบรับรองแพทย์ ")])])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"email"}},[_vm._v("อีเมล์")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.email),expression:"registerForm.email"}],staticClass:"form-control required",class:{
                          'is-invalid':
                            _vm.$v.registerForm.email.$error || _vm.isEmailDuplicate,
                        },attrs:{"type":"text","id":"email"},domProps:{"value":(_vm.registerForm.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "email", $event.target.value)},function($event){_vm.isEmailDuplicate = false}]}}),(
                          _vm.$v.registerForm.email.$error || _vm.isEmailDuplicate
                        )?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.email.required)?_c('span',[_vm._v("*กรุณาใส่อีเมลล์")]):(!_vm.$v.registerForm.email.email)?_c('span',[_vm._v("กรุณาใส่อีเมลล์ให้ถูกต้อง ตัวอย่าง example@email.com")]):(_vm.isEmailDuplicate)?_c('span',[_vm._v("อีเมลเคยลงทะเบียนแล้ว")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"password"}},[_vm._v("รหัสผ่าน")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.password),expression:"registerForm.password"}],staticClass:"form-control required",class:{
                          'is-invalid': _vm.$v.registerForm.password.$error,
                        },attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.registerForm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "password", $event.target.value)}}}),(_vm.$v.registerForm.password.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.password.required)?_c('span',[_vm._v("*กรุณาใส่รหัสผ่าน")]):(!_vm.$v.registerForm.password.minLength)?_c('span',[_vm._v("*รหัสผ่านต้องมีอย่างน้อย 8 ตัวขึ้นไป")]):(!_vm.$v.registerForm.password.passwordFormat)?_c('span',[_vm._v("*รหัสผ่านต้องประกอบไปด้วยตัวเลข (0-9) และตัวอักษรภาษาอังกฤษ (A-Z, a-z) อย่างน้อย 1 ตัว")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"confirmPassword"}},[_vm._v("ยืนยันรหัสผ่าน")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.confirmPassword),expression:"registerForm.confirmPassword"}],staticClass:"form-control required",class:{
                          'is-invalid':
                            _vm.$v.registerForm.confirmPassword.$error,
                        },attrs:{"type":"password","id":"confirmPassword"},domProps:{"value":(_vm.registerForm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "confirmPassword", $event.target.value)}}}),(_vm.$v.registerForm.confirmPassword.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.confirmPassword.required)?_c('span',[_vm._v("*กรุณาใส่รหัสผ่าน")]):(
                            !_vm.$v.registerForm.confirmPassword.sameAsPassword
                          )?_c('span',[_vm._v("*รหัสผ่านไม่ตรงกับรหัสผ่าน")]):_vm._e()]):_vm._e()])])]),_c('hr'),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"firstNameTh"}},[_vm._v("ชื่อภาษาไทย")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.firstNameTh),expression:"registerForm.firstNameTh"}],staticClass:"form-control required",class:{
                          'is-invalid': _vm.$v.registerForm.firstNameTh.$error,
                        },attrs:{"type":"text","id":"firstNameTh"},domProps:{"value":(_vm.registerForm.firstNameTh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "firstNameTh", $event.target.value)}}}),(_vm.$v.registerForm.firstNameTh.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.firstNameTh.required)?_c('span',[_vm._v("*กรุณาใส่ชื่อภาษาไทย")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"lastNameTh"}},[_vm._v("นามสกุลภาษาไทย")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.lastNameTh),expression:"registerForm.lastNameTh"}],staticClass:"form-control required",class:{
                          'is-invalid': _vm.$v.registerForm.lastNameTh.$error,
                        },attrs:{"type":"text","id":"lastNameTh"},domProps:{"value":(_vm.registerForm.lastNameTh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "lastNameTh", $event.target.value)}}}),(_vm.$v.registerForm.lastNameTh.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.lastNameTh.required)?_c('span',[_vm._v("*กรุณาใส่นามสกุลภาษาไทย")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"firstNameEn"}},[_vm._v("ชื่อภาษาอังกฤษ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.firstNameEn),expression:"registerForm.firstNameEn"}],staticClass:"form-control required",class:{
                          'is-invalid': _vm.$v.registerForm.firstNameEn.$error,
                        },attrs:{"type":"text","id":"firstNameEn"},domProps:{"value":(_vm.registerForm.firstNameEn)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "firstNameEn", $event.target.value)}}}),(_vm.$v.registerForm.firstNameEn.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.firstNameEn.required)?_c('span',[_vm._v("*กรุณาใส่ชื่อภาษาอังกฤษ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"lastNameEn"}},[_vm._v("นามสกุลภาษาอังกฤษ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.lastNameEn),expression:"registerForm.lastNameEn"}],staticClass:"form-control required",class:{
                          'is-invalid': _vm.$v.registerForm.lastNameEn.$error,
                        },attrs:{"type":"text","id":"lastNameEn"},domProps:{"value":(_vm.registerForm.lastNameEn)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "lastNameEn", $event.target.value)}}}),(_vm.$v.registerForm.lastNameEn.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.lastNameEn.required)?_c('span',[_vm._v("*กรุณาใส่นามสกุลภาษาอังกฤษ")]):_vm._e()]):_vm._e()])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"mobile"}},[_vm._v("เบอร์โทรศัพท์มือถือ")]),_c('b-input',{staticClass:"form-control required",class:{
                          'is-invalid': _vm.$v.registerForm.mobile.$error,
                        },attrs:{"type":"text","maxlength":"10","id":"mobile"},model:{value:(_vm.registerForm.mobile),callback:function ($$v) {_vm.$set(_vm.registerForm, "mobile", $$v)},expression:"registerForm.mobile"}}),(_vm.$v.registerForm.mobile.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.mobile.required)?_c('span',[_vm._v("*กรุณาใส่เบอร์โทรศัพท์")]):(!_vm.$v.registerForm.mobile.digits)?_c('span',[_vm._v("*เบอร์โทรศัพท์ต้องมีตัวเลข 10 ตัว")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"userType"}},[_vm._v("ประเภทผู้ใช้งาน")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.userTypeId),expression:"registerForm.userTypeId"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.$v.registerForm.userTypeId.$error,
                        },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.registerForm, "userTypeId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.changeUserType()}]}},[_vm._l((_vm.userTypeItemOptions),function(val,index){return [_c('option',{key:index,domProps:{"value":val.value}},[_vm._v(" "+_vm._s(val.label)+" ")])]})],2),(_vm.$v.registerForm.userTypeId.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.userTypeId.notValue)?_c('span',[_vm._v("*กรุณาเลือกประเภทผู้ใช้งาน")]):_vm._e()]):_vm._e()])]),(_vm.registerForm.userTypeId == '2')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"drLicenseNo"}},[_vm._v("เลข ท.")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.drLicenseNo),expression:"registerForm.drLicenseNo"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.registerForm.drLicenseNo.$error,
                          },attrs:{"type":"text","maxlength":"5","id":"drLicenseNo"},domProps:{"value":(_vm.registerForm.drLicenseNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "drLicenseNo", $event.target.value)}}}),(_vm.$v.registerForm.drLicenseNo.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.drLicenseNo.required)?_c('span',[_vm._v("*กรุณาใส่เลข ท.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"mt-1 mb-0",attrs:{"for":"selectedDrSpecialItemOptions"}},[_vm._v("สาขาเฉพาะทาง")]),_c('multiselect',{class:{
                            'is-invalid':
                              _vm.$v.registerForm.drSpecialItems.$error,
                          },attrs:{"options":_vm.drSpecialItemOptions,"multiple":true,"track-by":"val","custom-label":_vm.customLabel,"close-on-select":false},on:{"input":function($event){return _vm.selectDrSpecialItems()}},model:{value:(_vm.selectedDrSpecialItemOptions),callback:function ($$v) {_vm.selectedDrSpecialItemOptions=$$v},expression:"selectedDrSpecialItemOptions"}}),(_vm.$v.registerForm.drSpecialItems.$error)?_c('div',{staticClass:"description error"},[(!_vm.$v.registerForm.drSpecialItems.required)?_c('span',[_vm._v("*กรุณาเลือกสาขาเฉพาะทาง")]):_vm._e()]):_vm._e()],1)])]:_vm._e()],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-danger btn-block btn-sm",attrs:{"type":"reset"}},[_vm._v(" รีเซต ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-primary btn-block btn-sm",attrs:{"type":"submit"}},[_vm._v(" ยืนยัน ")])])])])]:_vm._e(),(_vm.registerStatus == true)?[_vm._m(0)]:_vm._e()],2)])])])]),(_vm.isLoading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"text-warning text-center"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" กรุณาตรวจสอบอีเมล ")]),_c('p',{staticClass:"text-center"},[_vm._v(" เพื่อทำการยืนยันการลงทะเบียนของท่าน ")]),_c('p',{staticClass:"text-center"},[_vm._v("หากไม่พบ กรุณาเช็ค junk mail")]),_c('a',{staticClass:"btn btn-block btn-primary",attrs:{"href":"/login"}},[_vm._v("ลงชื่อเข้าใข้")])])])}]

export { render, staticRenderFns }
<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Logout",
  components: {},
  created() {
    this.actionLogoutApi();
    this.logout().then((res) => {
      this.$router.push("/login");
    });
  },
  methods: {
    ...mapActions({
      logout: "moduleUser/logout",
      actionLogoutApi: "moduleAuth/userLogout",
    }),

    // ...mapActions("auth", {
    //   actionLogoutApi: "userLogout",
    // }),
  },
};
</script>

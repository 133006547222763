<template>
  <b-form @submit.prevent="submitForm">
    <div class="row mb-3">
      <div class="col-12">
        <div class="form-group">
          <label for="oldPassword">รหัสผ่านเดิม</label>
          <input
            id="oldPassword"
            type="password"
            class="form-control required"
            :class="{ 'is-invalid': $v.passwordForm.oldPassword.$error }"
            v-model="passwordForm.oldPassword"
            required
          />
          <div
            class="description error"
            v-if="$v.passwordForm.oldPassword.$error"
          >
            *กรุณาใส่รหัสผ่านเดิม
          </div>
          <b-tooltip
            :show.sync="isError400"
            title="รหัสผ่านผิด"
            variant="danger"
            target="oldPassword"
            placement="top"
            triggers="manual"
          ></b-tooltip>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="newPassword">รหัสผ่านใหม่</label>
          <input
            id="newPassword"
            type="password"
            autocomplete="new-password"
            class="form-control required"
            :class="{ 'is-invalid': $v.passwordForm.newPassword.$error }"
            v-model="passwordForm.newPassword"
            required
          />
          <div
            class="description error"
            v-if="$v.passwordForm.newPassword.$error"
          >
            <span v-if="!$v.passwordForm.newPassword.required"
              >*กรุณาใส่รหัสผ่านใหม่</span
            >
            <span v-else-if="!$v.passwordForm.newPassword.minLength"
              >*รหัสผ่านต้องมีอย่างน้อย 8 ตัวขึ้นไป</span
            >
            <span v-else-if="!$v.passwordForm.newPassword.passwordFormat"
              >*รหัสผ่านต้องประกอบไปด้วยตัวเลข (0-9) และตัวอักษรภาษาอังกฤษ (A-Z,
              a-z) อย่างน้อย 1 ตัว</span
            >
          </div>
          <b-tooltip
            :show.sync="isError404"
            title="เคยใช้รหัสผ่านนี้แล้ว"
            variant="danger"
            target="newPassword"
            placement="top"
            triggers="manual"
          ></b-tooltip>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="confirmPassword">ยืนยันรหัสผ่านใหม่</label>
          <input
            id="confirmPassword"
            type="password"
            autocomplete="new-password"
            class="form-control required"
            :class="{ 'is-invalid': $v.passwordForm.confirmPassword.$error }"
            v-model="passwordForm.confirmPassword"
            required
          />
          <div
            class="description error"
            v-if="$v.passwordForm.confirmPassword.$error"
          >
            <span v-if="!$v.passwordForm.confirmPassword.required"
              >*กรุณาใส่ยืนยันรหัสผ่านใหม่</span
            >
            <span v-else-if="!$v.passwordForm.confirmPassword.sameAsPassword"
              >*รหัสผ่านไม่ตรงกับรหัสผ่านใหม่</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="submit" class="btn btn-block btn-primary">
          เปลี่ยนรหัสผ่าน
        </button>
      </div>
      <div class="col-12 mt-4 text-center">
        <a href="/user/forgotpassword" class="text-center">ลืมรหัสผ่าน?</a>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
} from "vuelidate/lib/validators";

const passwordFormat = helpers.regex(
  "passwordFormat",
  /^(?=.*[A-Za-z])(?=.*\d)[\u0E00-\u0E7FA-Za-z\d@$!%*#?&]{1,}$/
);

export default {
  name: "ChangePasswordForm",
  components: {
    Loading,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      responseCode: null,
      tooltipTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
    }),
    isError400() {
      return this.responseCode == 400;
    },
    isError404() {
      return this.responseCode == 404;
    },
  },
  validations: {
    passwordForm: {
      oldPassword: { required },
      newPassword: {
        required,
        minLength: minLength(8),
        passwordFormat: passwordFormat,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("newPassword"),
      },
    },
  },
  methods: {
    ...mapActions({
      changePassword: "moduleUser/changePassword",
    }),
    resetForm() {
      this.isLoading = false;
      this.responseCode = null;
      this.passwordForm.oldPassword = "";
      this.passwordForm.newPassword = "";
      this.passwordForm.confirmPassword = "";
      this.$v.$reset();
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      var passwordData = {
        uid: this.userInfo.uid,
        oldPassword: this.passwordForm.oldPassword,
        password: this.passwordForm.newPassword,
      };

      this.isLoading = true;
      this.changePassword(passwordData)
        .then((res) => {
          this.isLoading = false;
          this.responseCode = res.status;
          if (res.status == 200) {
            this.resetForm();
            this.showDialogToast("success", `เปลี่ยนรหัสผ่านสำเร็จ`);
            this.$root.$emit("bv::hide::modal", "UserProfileModal");
          }
        })
        .catch((res) => {
          this.isLoading = false;
          this.responseCode = res.response.status;
          this.hideTooltip(5000);
          switch (res.response.status) {
            case 400:
              this.showDialogToast("danger", `ใส่รหัสผ่านเดิมไม่ถูกต้อง`);
              break;
            case 404:
              this.showDialogToast("danger", `รหัสผ่านใหม่นี้เคยถูกใช้มาแล้ว`);
              break;
            default:
              this.showDialogToast("danger", `ไม่สามารถเปลี่ยนรหัสผ่านได้`);
          }
        });
    },
    hideTooltip(delay) {
      clearTimeout(this.tooltipTimeout);
      this.tooltipTimeout = setTimeout(() => {
        this.responseCode = null;
      }, delay);
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
  },
};
</script>

<style>
</style>
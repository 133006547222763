<template>
  <div>
    <div class="stage-container">
      <canvas ref="pdfCanvas" style="display: none"></canvas>
      <v-stage
        ref="stage"
        :config="configKonva"
        @mousedown="startDrawing"
        @touchstart="checkPen"
        @mouseup="stopDrawing"
        @touchend="stopDrawing"
        @mousemove="drawing"
        @touchmove="drawing"
      >
        <v-layer>
          <v-image :config="pdfImageConfig"></v-image>
          <v-line
            v-for="(line, index) in lines"
            :key="index"
            :config="line"
          ></v-line>
        </v-layer>
      </v-stage>
    </div>
    <span @click="closeTab" class="close-button" aria-label="Close">
      &times;
    </span>
    <div class="sticky-buttons">
      <b-button class="mt-2" variant="outline-danger" @click="resetDrawing"
        ><i class="fas fa-trash"></i
      ></b-button>
      <b-button class="mt-2" variant="outline-primary" @click="exportToPDF"
        ><i class="fas fa-upload"></i
      ></b-button>
      <b-button
        class="mt-2"
        variant="outline-success"
        @click="exportToPDF({ isPrint: true })"
        ><i class="fas fa-print"></i
      ></b-button>
    </div>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Vue from "vue";
import VueKonva from "vue-konva";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dialog from "@/components/modal/Dialog";

import { eventBus } from "@/main";
Vue.use(VueKonva);

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: "PdfKonva",
  components: { Dialog },
  data() {
    return {
      pdfUrl: null, // Path to your PDF file
      docId: null,
      patientId: null,
      clinicUrl: null,
      branchUrl: null,
      pdfImageConfig: {
        image: null,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      configKonva: {
        width: 800, // Set the stage width
        height: 600, // Set the stage height
      },
      lines: [],
      isDrawing: false,
      currentLine: [],
      isPen: false,
    };
  },
  mounted() {
    eventBus.$on("renderPDF", this.renderPDF);
    window.addEventListener("pointerdown", e => {
      this.isPen = e.pointerType === "pen";
    });
    const query = this.$route.query;
    console.log(query);

    if (this.$route.query) {
      this.pdfUrl = this.$route.query.src;
      this.docId = parseInt(this.$route.query.id);
      this.patientId = parseInt(this.$route.query.patientId);
      this.clinicUrl = this.$route.query.clinicUrl;
      this.branchUrl = this.$route.query.branchUrl;
    }

    this.renderPDF(this.pdfUrl);
  },
  methods: {
    ...mapActions({
      createMediaFiles: "moduleMediaFile/createMediaFiles",
    }),
    async renderPDF(pdfUrl) {
      console.log("render");
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);

      // Increase the scale factor for a sharper image
      const scale = 4.0; // Increase scale for higher resolution
      const viewport = page.getViewport({ scale });

      const canvas = this.$refs.pdfCanvas;
      const context = canvas.getContext("2d");

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      const image = new window.Image();
      image.src = canvas.toDataURL("application/pdf");
      image.onload = () => {
        // Calculate the aspect ratio
        const aspectRatio = canvas.width / canvas.height;

        // Set the Konva stage dimensions based on the scaled image
        const stageWidth = this.configKonva.width;
        const stageHeight = stageWidth / aspectRatio;

        this.configKonva.width = stageWidth;
        this.configKonva.height = stageHeight;

        this.pdfImageConfig = {
          image: image,
          x: 0,
          y: 0,
          width: stageWidth,
          height: stageHeight,
        };
      };
    },
    checkPen(e) {
      if (!this.isPen) return;
      this.startDrawing(e);
    },
    startDrawing(e) {
      this.isDrawing = true;
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();
      this.currentLine = {
        points: [pos.x, pos.y],
        stroke: "black",
        strokeWidth: 2,
        lineCap: "round",
        lineJoin: "round",
        tension: 0.5,
      };
      this.lines.push(this.currentLine);
    },
    drawing(e) {
      if (!this.isDrawing) return;
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();
      const newPoints = this.currentLine.points.concat([pos.x, pos.y]);
      this.currentLine.points = newPoints;
    },
    stopDrawing() {
      this.isDrawing = false;
      let points = this.chunkArrayInGroups(this.currentLine.points, 2); //grouping array
      let simplifiedPoints = this.simplifyLineRDP(points, 1); //ลดจำนวน points ทำให้ เส้น smooth มากขึ้น ตัวเลข คือระยะหว่างจุดที่จะนำมาคำนวน
      this.currentLine.points = [].concat.apply([], simplifiedPoints); //flatten array
    },
    resetDrawing() {
      this.$refs.Dialog.showAlertConfirm(
        "ลบลายเซ็นต์ทั้งหมด ?",
        "คุณต้องการลบลายเซ็นต์ทั้งหมด",
        null,
        "ยืนยัน",
        "ยกเลิก"
      ).then(result => {
        if (result.value) this.lines = [];
      });
    },
    chunkArrayInGroups(arr, size) {
      var result = [];
      for (var i = 0; i < arr.length; i += size)
        result.push(arr.slice(i, i + size));
      return result;
    },
    simplifyLineRDP(points, length) {
      var simplify = function (start, end) {
        // recursize simplifies points from start to end
        var maxDist, index, xx, yy, dx, dy, ddx, ddy, p1, p2, p, t, dist, dist1;
        p1 = points[start];
        p2 = points[end];
        xx = p1[0];
        yy = p1[1];
        ddx = p2[0] - xx;
        ddy = p2[1] - yy;
        dist1 = ddx * ddx + ddy * ddy;
        maxDist = length;
        for (var i = start + 1; i < end; i++) {
          p = points[i];
          if (ddx !== 0 || ddy !== 0) {
            t = ((p[0] - xx) * ddx + (p[1] - yy) * ddy) / dist1;
            if (t > 1) {
              dx = p[0] - p2[0];
              dy = p[1] - p2[1];
            } else if (t > 0) {
              dx = p[0] - (xx + ddx * t);
              dy = p[1] - (yy + ddy * t);
            } else {
              dx = p[0] - xx;
              dy = p[1] - yy;
            }
          } else {
            dx = p[0] - xx;
            dy = p[1] - yy;
          }
          dist = dx * dx + dy * dy;
          if (dist > maxDist) {
            index = i;
            maxDist = dist;
          }
        }

        if (maxDist > length) {
          // continue simplification while maxDist > length
          if (index - start > 1) {
            simplify(start, index);
          }
          newLine.push(points[index]);
          if (end - index > 1) {
            simplify(index, end);
          }
        }
      };
      var end = points.length - 1;
      var newLine = [points[0]];
      simplify(0, end);
      newLine.push(points[end]);
      return newLine;
    },
    exportToPDF({ isPrint, isDownload }) {
      const stage = this.$refs.stage.getStage();
      const pixelRatio = 4.0; // Increase the pixelRatio for higher resolution

      // Generate a high-resolution image
      const dataURL = stage.toDataURL({ pixelRatio });

      // Create an off-screen canvas to compress the image
      const offScreenCanvas = document.createElement("canvas");
      const offScreenContext = offScreenCanvas.getContext("2d");

      // Set the dimensions of the off-screen canvas
      offScreenCanvas.width = stage.width() * pixelRatio;
      offScreenCanvas.height = stage.height() * pixelRatio;

      // Create an image element
      const img = new Image();
      img.src = dataURL;
      img.onload = async () => {
        // Draw the image on the off-screen canvas
        offScreenContext.drawImage(
          img,
          0,
          0,
          offScreenCanvas.width,
          offScreenCanvas.height
        );

        // Convert the canvas to a data URL with reduced quality
        const compressedDataURL = offScreenCanvas.toDataURL("image/jpeg", 0.7); // Adjust the quality as needed

        // Define PDF dimensions
        const pdfWidth = stage.width(); // Use original dimensions for PDF
        const pdfHeight = stage.height();

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: [pdfWidth, pdfHeight],
        });

        // Add the compressed image to the PDF
        pdf.addImage(compressedDataURL, "JPEG", 0, 0, pdfWidth, pdfHeight);
        const blob = pdf.output("blob");

        if (isPrint) {
          const pdfDataUrl = pdf.output("dataurlstring");
          // Create an overlay
          // Create an overlay
          const overlay = document.createElement("div");
          overlay.style.position = "fixed";
          overlay.style.top = "0";
          overlay.style.left = "0";
          overlay.style.width = "100%";
          overlay.style.height = "100%";
          overlay.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
          overlay.style.zIndex = "1000";
          overlay.style.display = "flex";
          overlay.style.justifyContent = "center";
          overlay.style.alignItems = "center";
          overlay.style.flexDirection = "column";

          // Create a close button
          const closeButton = document.createElement("button");
          closeButton.innerText = "X";
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.padding = "10px 20px";
          closeButton.style.fontSize = "16px";
          closeButton.style.backgroundColor = "#fff";
          closeButton.style.border = "none";
          closeButton.style.borderRadius = "50%";
          closeButton.style.cursor = "pointer";
          closeButton.onclick = () => {
            document.body.removeChild(overlay);
          };

          // Create an iframe to display the PDF
          const pdfIframe = document.createElement("iframe");
          pdfIframe.src = pdfDataUrl;
          pdfIframe.style.width = "80%";
          pdfIframe.style.height = "80%";
          pdfIframe.style.border = "none";

          // Append elements to the overlay
          overlay.appendChild(closeButton);
          overlay.appendChild(pdfIframe);

          // Append overlay to the body
          document.body.appendChild(overlay);
        } else {
          await this.handleCreateMediaFile(blob);
          this.$refs.Dialog.showAlertInfo("Upload ไฟล์สำเร็จ", null, "success");
          localStorage.setItem(
            "crossTabEvent",
            JSON.stringify({
              type: "POLL_FOR_UPDATE",
              payload: { patientId: this.patientId, files: [blob] },
            })
          );
        }
      };
    },
    async handleCreateMediaFile(pdfBlob) {
      try {
        eventBus.$emit("appLoadingStatus", true);

        await this.createMediaFiles({
          clinicUrl: this.clinicUrl,
          branchUrl: this.branchUrl,
          data: {
            category: "Document",
            patientId: this.patientId ? String(this.patientId) : null,
            remark: `Signed_Document_${this.$utils.generateDateTimeString()}`,
            filesMetadata: [{ id: null, contentType: "application/pdf" }],
          },
          files: [pdfBlob],
        });
      } catch (error) {
        console.error(error);
      } finally {
        eventBus.$emit("appLoadingStatus", false);
      }
    },
    closeTab() {
      this.$refs.Dialog.showAlertConfirm(
        "ปิดหน้าจอนี้?",
        "คุณต้องการปิดหน้าจอนี้",
        null,
        "ยืนยัน",
        "ยกเลิก"
      ).then(result => {
        if (result.value) window.close();
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("pointerdown", e => {
      this.isPen = e.pointerType === "pen";
    });
  },
};
</script>

<style>
.stage-container {
  border: 2px solid #333;
  border-radius: 10px;
  margin: 1rem;
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.sticky-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
}
.close-button {
  font-size: 1.5rem;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  cursor: pointer;
}
</style>
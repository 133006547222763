import axios from "axios";
import numeral from "numeral";
import { eventBus } from "@/main";

const moduleTreatment = {
  namespaced: true,
  state: {
    orthoPackageList: [], // list package ที่ขึ้นมาใน bracch
    orthoPackageUserList: [], // list package ที่สร้างขึ้นมาใหม่ทุกครั้ง ที่เริ่มแพ็กเกจ
    templateOrthoPackageList: [], //branch packages include template (branchId = 0)
    orthoPackageItemList: [],
    treatmentHistoryList: [],

    patientOrthoPackage: {},
    patientOrthoPackageItems: []
  },
  mutations: {
    setOrthoPackageList(state, data) {
      state.orthoPackageList = data;
    },
    setOrthoPackageUserList(state, data) {
      state.orthoPackageUserList = data;
    },
    setTemplateOrthoPackageList(state, data) {
      state.templateOrthoPackageList = data;
    },
    //items ของแพ็กเกจแรกเริ่มที่ยังไม่ได้ assign ให้คนไข้
    setOrthoPackageItemList(state, data) {
      state.orthoPackageItemList = data;
    },
    //แพ็กเกจของคนไข้
    setPatientOrthoPackage(state, data) {
      state.patientOrthoPackage = data;
    },
    //items ของแพ็กเกจของคนไข้
    setPatientOrthoPackageItems(state, data) {
      state.patientOrthoPackageItems = data;
    },

    setTreatmentHistoryList(state, data) {
      state.treatmentHistoryList = data;
    },
    updateOrthoPackageItem(state, data) {
      var index = state.patientOrthoPackageItems.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.patientOrthoPackageItems[index], data);
      }
    }
  },
  actions: {
    //Tretament
    createTreatment({ commit, getters }, payload) {
      return axios
        .post(
          `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}`,
          // `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          if (err.response.data === "TREAT-EXISTED") {
            throw err;
          }
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateTreatment({ commit, getters }, payload) {
      let event = { ...payload.event };
      delete event.id;
      return axios
        .put(
          `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          if (err.response.data === "PAYMENT-COMPLETED") {
            throw err;
          }
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    // --------- drawNote ----------//
    updateDrawNote({ commit, getters }, payload) {
      return axios
        .put(
          `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //Ortho Package
    fetchOrthoPackageList({ commit, getters }, payload) {
      return axios
        .get(
          `/api/orthoPackage/master/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          const templatePackageList = res.data;
          //branch packages include template (branchId = 0)
          commit("setTemplateOrthoPackageList", templatePackageList);

          const branchPackageList = res.data.filter(i => i.branchId !== 0);
          commit("setOrthoPackageList", branchPackageList);
          return branchPackageList;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async fetchOrthoPackageUserList({ commit, getters }, payload) {
      // ไม่ได้ใช้
      return await axios
        .get(
          `/api/orthoPackage/${payload.clinicUrl}/${payload.branchUrl}` // ยังไม่ได้เช็๕
          // `/api/master/getOrthoPackageUser/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          commit("setOrthoPackageUserList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchTemplateOrthoPackageList({ commit, getters }, payload) {
      return axios
        .get(
          `/api/orthoPackage/master/${payload.clinicUrl}/${payload.branchUrl}`
          // `/api/orthoPackage/master/templates/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          commit("setTemplateOrthoPackageList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchOrthoPackageItemList({ commit, getters, state }, payload) {
      const [data] = state.orthoPackageList.filter(
        i => i.id === payload.packageId
      );
      commit("setOrthoPackageItemList", data.items);
      return data.items;
    },
    fetchOrthoPackageItemActiveList({ commit, getters }, payload) {
      return axios
        .get(
          `/api/orthoPackage/user/${payload.patientId}/item/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          commit("setPatientOrthoPackageItems", res.data);
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchPatientOrthoPackage({ commit, getters }, payload) {
      //TODO ส่ง items มาด้วยเลย (fetchOrthoPackageItemActiveList)
      return axios
        .get(
          `/api/orthoPackage/user/${payload.clinicUrl}/${payload.branchUrl}`,
          { params: { uid: payload.patientId } }
        )
        .then(res => {
          commit("setPatientOrthoPackage", res.data[0]);
          commit(
            "setPatientOrthoPackageItems",
            res.data[0]?.orthoPackage?.items
          );

          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    deleteUserOrthoPackage({ commit, getters }, payload) {
      return axios
        .put(
          `/api/orthoPackage/${payload.id}/user/status/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            status: 2
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    successUserOrthoPackage({ commit, getters }, payload) {
      return (
        axios
          // .post(
          //   `/api/userOrthgoPackage/successUserOrthoPackage/${payload.clinicUrl}/${payload.branchUrl}/${payload.patientOrthoPackage.id}`
          // )
          .put(
            `/api/orthoPackage/${payload.patientOrthoPackage.id}/user/status/${payload.clinicUrl}/${payload.branchUrl}`,
            {
              status: 3
            }
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    getOrthoPackageById({ commit, getters }, payload) {
      return axios
        .get(
          `/api/orthoPackage/${payload.event}/master/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    getOrthoPackageUserById({ commit, getters }, payload) {
      return axios
        .get(
          `/api/orthoPackage/user/${payload.clinicUrl}/${payload.branchUrl}`,
          { params: { packageID: payload.id } }
        )
        .then(res => {
          return res.data[0].orthoPackage;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    createOrthoPackage({ commit, getters }, payload) {
      return axios
        .post(
          `/api/orthoPackage/master/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          if (err.response.data === "duplicated master ortho package name") {
            return err.response;
          }
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    assignPatientToOrthoPackage({ commit, getters }, payload) {
      // เป็นตัว assign patientId ให้ userpackage
      return axios
        .post(
          `/api/orthoPackage/user/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.package
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createOrthoPackageUser({ commit, getters }, payload) {
      return axios
        .post(
          `/api/orthoPackage/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateOrthoPackage({ commit, getters }, payload) {
      const id = payload.event.id;
      delete payload.event.id;
      return axios
        .put(
          `/api/orthoPackage/${id}/master/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          if (err.response.data === "duplicated master ortho package name") {
            return err.response;
          }
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteOrthoPackage({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/orthoPackage/${payload.event.id}/master/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateStateOrthoPackageItem({ commit, getters }, data) {
      commit("updateOrthoPackageItem", data);
    },

    // Treatment
    fetchTreatmentHistoryList({ commit, getters }, payload) {
      commit("setTreatmentHistoryList", []);
      return axios
        .get(`/api/treatment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: { patientId: payload.patientId }
        })
        .then(res => {
          commit("setTreatmentHistoryList", res.data.treatments);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchTreatmentByApptId({ commit, getters }, payload) {
      return axios
        .get(
          `/api/treatment/byAppointmentID/${payload.clinicUrl}/${payload.branchUrl}/${payload.appointmentId}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchItemsPaymentByAptId({ commit, getters }, payload) {
      return axios
        .get(`/api/treatment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: { appointmentId: payload.appointmentId, forPayment: true }
        })
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchOverdueTreatmentByPatientId({ commit, getters }, payload) {
      return axios
        .get(
          `/api/treatment/overduePayment/items/${payload.clinicUrl}/${payload.branchUrl}/${payload.patientId}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteOverdueItem({ commit, getters }, payload) {
      return axios
        .put(
          `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}/item/${payload.event.id}/overdue`,
          { password: payload.password, overdueDeletedReason: payload.reason }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAuditTreamentById({ commit, getters }, payload) {
      return axios
        .get(
          `/api/treatment/audit/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    }
  },
  getters: {
    findBy: () => (dataList, value, key, defaultVal) => {
      if (!value) {
        return defaultVal;
      }
      return (
        dataList.find(function(item) {
          return item[key] == value;
        }) || defaultVal
      );
    },
    getOrthoPackageList: state => {
      return state.orthoPackageList;
    },
    getOrthoPackageBy: (state, getters) => (value, key) => {
      return getters.findBy(state.orthoPackageList, value, key, null);
    },
    getOrthoPackageUserList: state => {
      return state.orthoPackageUserList;
    },
    getOrthoPackageUserBy: (state, getters) => (value, key) => {
      return getters.findBy(state.orthoPackageUserList, value, key, null);
    },
    getTemplateOrthoPackageList: state => {
      return state.templateOrthoPackageList;
    },
    getGroupedTemplateOrthoPackageList: (state, getters) => {
      const grouped = Object.values(
        getters.getTemplateOrthoPackageList.reduce((result, item) => {
          if (!result[item.branchId]) {
            result[item.branchId] = {
              category: item.branchId === 0 ? "Templates" : "แพ็กเกจที่มีอยู่",
              libs: []
            };
          }
          result[item.branchId].libs.push(item);
          return result;
        }, {})
      );
      const newPackage = [
        {
          category: "แพ็กเกจใหม่",
          libs: [
            {
              id: -1,
              nameTh: "สร้างแพ็กเกจใหม่",
              nameEn: "Create new package"
            }
          ]
        }
      ];
      return newPackage.concat(grouped);
    },
    getTemplateOrthoPackageBy: (state, getters) => (value, key) => {
      return getters.findBy(state.templateOrthoPackageList, value, key, null);
    },
    getOrthoPackageItemList: state => {
      return state.orthoPackageItemList;
    },
    getOrthoPackageItemBy: (state, getters) => (value, key) => {
      return getters.findBy(state.orthoPackageItemList, value, key, null);
    },
    getPatientOrthoPackage: state => {
      return state.patientOrthoPackage;
    },
    getPatientOrthoPackageItems: state => {
      return state.patientOrthoPackageItems;
    },
    getPatientOrthoPackageItemBy: (state, getters) => (value, key) => {
      return getters.findBy(state.patientOrthoPackageItems, value, key, null);
    },
    getTreatmentHistoryList: state => {
      return state.treatmentHistoryList;
    }
  }
};
export default moduleTreatment;

import moment from "moment";

export function removeDuplicates(array, key) {
  return [...new Map(array.map(item => [item[key], item])).values()];
}

export function formatDateTime(date) {
  if (!date) return "";
  return moment(date).locale("th").format("llll");
}

export function formatDate(date) {
  if (!date) return "";
  return moment(date).locale("th").format("D MMM YY");
}

export function generateDateTimeString(dateTime = new Date()) {
  const now = new Date();

  // การดึงส่วนของวันที่และเวลา
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0"); // เดือนเริ่มจาก 0, ต้องบวก 1
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  // การสร้างชื่อไฟล์
  return `${year}${month}${day}_${hours}${minutes}${seconds}.`;
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true; // Same reference

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false; // Not objects or null
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}
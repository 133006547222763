<template>
  <img v-if="logo" class="img-fluid" :src="logo" :alt="alt" />
</template>

<script>
export default {
  name: "Logo",
  components: {},
  props: {
    src: { type: String, default: "" },
    alt: { type: String, default: "" },
    default: { type: String, default: "" }, //`${window.location.origin}/assets/img/default-img.png`
    callback: { type: Function, default: () => {} },
  },
  data() {
    return {
      logo: null,
    };
  },
  mounted() {
    this.loadLogo();
  },
  watch: {
    src: function() { 
      this.loadLogo();
    }
  },
  methods: {
    loadLogo() {
      this.logo = null;
      const logo = new Image();
      logo.onload = this.onLoadSuccess;
      logo.onerror = this.onLoadFail;
      logo.src = this.src;
    },
    onLoadSuccess() {
      this.logo = this.src;
      this.callback(this.logo);
    },
    onLoadFail() {
      this.logo = this.default;
      this.callback(this.logo);
    },
  },
};
</script>


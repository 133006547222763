<template>
  <div class="avatar-container text-center">
    <div class="upload-btn-wrapper">
      <button class="btn-border">
        <div :class="{ 'rounded-circle': !square, 'img-thumbnail': border }">
          <div class="avatar-image">
            <b-avatar
              :square="square"
              :variant="variant"
              :size="size"
              :alt="alt"
              :src="avatarImage"
            ></b-avatar>
          </div>
          <div
            class="avatar-text"
            :class="{ 'rounded-circle': !square }"
            :style="{
              width: `${size}`,
              height: `${size}`,
              'padding-top': border ? '46%' : '50%',
              'margin-top': border ? '0.26rem' : '0',
            }"
          >
            เปลี่ยนรูป
          </div>
        </div>
      </button>
      <input
        ref="InputFile"
        accept="image/*"
        type="file"
        @change="changeImage($event)"
      />
    </div>
    <b-badge
      v-if="avatarImage"
      pill
      href="#"
      variant="danger"
      class="mt-1 py-1"
      @click="deleteImage"
    >
      <b-icon icon="x-circle-fill"></b-icon>
      <span class="ml-1">ลบรูป</span>
    </b-badge>
    <b-badge
      v-else
      pill
      href="#"
      variant="success"
      class="mt-1 py-1"
      @click="$refs.InputFile.click()"
    >
      <b-icon icon="cloud-upload-fill"></b-icon>
      <span class="ml-1">อัพโหลดรูป</span>
    </b-badge>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const STATE_UPLOADING = "uploading";
const STATE_UPLOAD_SUCCESS = "success";
const STATE_UPLOAD_FAIL = "fail";
const STATE_CALLBACK = "callback";
const STATE_RESET = "reset";
const STATE_DELETE = "delete";

export default {
  name: "UploadAvatar",
  components: {},
  props: {
    defaultId: {
      type: [String, Number],
      default: null,
    },
    avartarClass: String,
    square: Boolean,
    border: Boolean,
    variant: String,
    size: {
      type: String,
      default: "100px",
    },
    uploadMaxSize: {
      type: Number,
      default: 100,
    },
    alt: {
      type: String,
      default: "Avatar Image",
    },
  },
  data() {
    return {
      contentId: null,
      avatarUrl: null,
      avatarFile: null,
    };
  },
  mounted() {
    this.contentId = this.defaultId;
  },
  computed: {
    ...mapGetters({
      viewAvatar: "moduleContent/viewAvatar",
    }),
    avatarImage() {
      return this.viewAvatar(this.contentId, this.avatarUrl);
    },
  },
  methods: {
    ...mapActions({
      uploadFile: "moduleContent/uploadFile",
    }),
    changeImage(event) {
      var files = event.target.files;
      var file = files && files[0] ? files[0] : null;
      if (file && file.type.match(/image.*/)) {
        var self = this;
        var reader = new FileReader();
        reader.onload = function (event) {
          self.setImage(event.target.result, file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    setImage(dataUrl, fileName) {
      var self = this;
      this.cropImage(dataUrl, 1).then((canvas) => {
        const dataUrl = canvas.toDataURL("image/jpeg");
        self.previewImage(dataUrl, fileName);
      });
    },
    previewImage(dataUrl, fileName) {
      this.contentId = null;
      this.avatarUrl = dataUrl;
      this.avatarFile = this.dataURLtoFile(dataUrl, fileName);
    },
    dataURLtoFile(dataUrl, fileName) {
      var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], fileName, { type: mime });
    },
    cropImage(url, aspectRatio) {
      var self = this;
      // we return a Promise that gets resolved with our canvas element
      return new Promise((resolve) => {
        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {
          // let's store the width and height of our image
          const inputWidth = inputImage.naturalWidth;
          const inputHeight = inputImage.naturalHeight;

          // get the aspect ratio of the input image
          const inputImageAspectRatio = inputWidth / inputHeight;

          // if it's bigger than our target aspect ratio
          let outputWidth = inputWidth;
          let outputHeight = inputHeight;
          if (inputImageAspectRatio > aspectRatio) {
            outputWidth = inputHeight * aspectRatio;
          } else if (inputImageAspectRatio < aspectRatio) {
            outputHeight = inputWidth / aspectRatio;
          }

          // calculate the position to draw the image at
          const outputX = (outputWidth - inputWidth) * 0.5;
          const outputY = (outputHeight - inputHeight) * 0.5;

          // create a canvas that will present the output image
          const outputImage = document.createElement("canvas");

          // resize image to not exceed the maximum size
          const max_size = self.uploadMaxSize;
          var width = outputWidth > max_size ? max_size : outputWidth;
          var height = outputHeight > max_size ? max_size : outputHeight;
          outputImage.width = width;
          outputImage.height = height;

          // draw our image at position 0, 0 on the canvas
          const ctx = outputImage.getContext("2d");
          ctx.scale(width / outputWidth, height / outputHeight);
          ctx.drawImage(inputImage, outputX, outputY);
          resolve(outputImage);
        };

        // start loading our image
        inputImage.src = url;
      });
    },
    clearInputImage() {
      this.avatarUrl = null;
      this.avatarFile = null;
      this.$refs.InputFile.value = "";
    },
    deleteImage() {
      this.clearInputImage();
      this.contentId = null;
      this.$emit("callback", STATE_DELETE, this.contentId);
    },
    reset(contentId) {
      this.clearInputImage();
      this.contentId =
        contentId || contentId == null ? contentId : this.defaultId;
      this.$emit("callback", STATE_RESET, this.contentId);
    },
    submit() {
      if (this.avatarFile) {
        this.$emit("callback", STATE_UPLOADING);
        this.uploadFile(this.avatarFile)
          .then((res) => {
            if (res.status == "200") {
              this.avatarUrl = null;
              this.avatarFile = null;
              this.contentId = res.data.id;
              this.$emit("callback", STATE_UPLOAD_SUCCESS, this.contentId);
            } else {
              this.$emit("callback", STATE_UPLOAD_FAIL);
            }
          })
          .catch((res) => {
            console.error(res);

            this.$emit("callback", STATE_UPLOAD_FAIL);
          });
      } else {
        this.$emit("callback", STATE_CALLBACK);
      }
    },
  },
};
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
}

.btn-border {
  border: none;
  color: gray;
  padding: 0;
  background-color: white;
  border-radius: 0;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.avatar-text {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, transparent 0);
  border-radius: 0;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 2.5em;
  margin: 0;
  padding-bottom: 8px;
  padding-top: 50%;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.upload-btn-wrapper:hover .avatar-text {
  visibility: visible;
}
</style>
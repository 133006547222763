<template>
  <nav
    class="main-header navbar navbar-expand navbar-white navbar-light component-menubar-navbar"
  >
    <ul id="navbar-left" class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="pushmenu"
          data-enable-remember="true"
          href="#"
        >
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <li
        class="nav-item"
        v-if="
          onQueueRoute && isAccessMenu(['MANAGER', 'COUNTER', 'HEADCOUNTER'])
        "
      >
        <a href="#" class="nav-link" @click="openCustomerModal">
          <i class="fa fa-user-plus" aria-hidden="true"></i> คนไข้ใหม่
        </a>
      </li>

      <li
        class="nav-item"
        v-if="
          (onQueueRoute || onDoctorsTxQRoute) &&
          isAccessMenu(['MANAGER', 'COUNTER', 'ASSISTANT', 'HEADCOUNTER'])
        "
      >
        <a href="#" class="nav-link" @click="openSearchCustomerModal">
          <i class="fa fa-search" aria-hidden="true"></i> ค้นหาคนไข้
        </a>
      </li>
    </ul>
    <div class="navbar-brand mx-auto p-0">
      <Logo
        class="logo my-auto d-none d-sm-inline-block px-2"
        :src="logoUrl"
        :alt="clinicName"
      />
      <h5 class="text-muted my-auto d-none d-sm-inline-block">
        {{ `${clinicName}`
        }}<span v-if="branchName" class="ml-2">({{ `${branchName}` }})</span>
      </h5>
    </div>
    <ul class="navbar-nav">
      <li v-if="isStaging" class="av-item d-inline-block">
        <a href="#" class="nav-link pt-2">
          token <CopyToClipboard :text="accessToken" />
        </a>
      </li>
      <li class="av-item d-inline-block">
        <a id="soundLockedIcon" href="#" class="nav-link pt-2">
          <i v-if="soundUnlocked && volume > 0" class="fas fa-volume-up"></i>
          <i v-else class="fas fa-volume-mute"></i>
        </a>
      </li>
      <b-popover
        target="soundLockedIcon"
        placement="bottomleft"
        triggers="click blur"
      >
        <b-form-input
          class="mb-0"
          type="range"
          :min="0"
          :max="0.6"
          :step="0.05"
          v-model="volume"
          @change="emitVolume"
        ></b-form-input>
      </b-popover>
      <li id="todoNotification" class="av-item d-none d-sm-inline-block">
        <a
          v-if="
            (onQueueRoute || onDoctorsTxQRoute) &&
            isAccessMenu(['MANAGER', 'COUNTER', 'ASSISTANT', 'HEADCOUNTER'])
          "
          href="#"
          class="nav-link"
        >
          <i class="fas fa-bell"></i
          ><b-badge v-if="todoNumber" class="" variant="warning">{{
            todoNumber
          }}</b-badge>
        </a>
      </li>

      <li class="nav-item">
        <b-dropdown
          size="lg"
          variant="link"
          right
          toggle-class="text-decoration-none p-0"
          no-caret
        >
          <template v-slot:button-content>
            <b-avatar
              :variant="profileInfo.contentId ? 'white' : 'info'"
              class="elevation-2"
              :text="abbreviation"
              alt="User Image"
              size="2.4rem"
              :src="viewFile(profileInfo.contentId)"
            ></b-avatar>
          </template>
          <b-dropdown-text style="width: 240px">
            <div class="user-panel d-flex">
              <div class="image pl-0">
                <b-avatar
                  :variant="profileInfo.contentId ? 'white' : 'info'"
                  :text="abbreviation"
                  alt="User Image"
                  size="2.4rem"
                  :src="viewFile(profileInfo.contentId)"
                ></b-avatar>
              </div>
              <div class="info">
                <div>
                  {{ `${profileInfo.firstNameTh} ${profileInfo.lastNameTh}` }}
                </div>
              </div>
            </div>
          </b-dropdown-text>
          <b-dropdown-divider></b-dropdown-divider>
          <!-- <b-dropdown-item href="/user/profile"> -->
          <b-dropdown-item @click="openUserProfileModal">
            <i class="fas fa-user-edit"></i>
            ข้อมูลผู้ใช้
          </b-dropdown-item>
          <b-dropdown-item href="/clinic">
            <i class="fas fa-clinic-medical"></i>
            เลือกคลินิก/สาขา
          </b-dropdown-item>
          <!-- <b-dropdown-item href="#">
            <img style="width: auto; height: 24px" :src="MorFunDeeLogo" />
            เข้าระบบ MorFunDee
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <img style="width: auto; height: 24px" :src="TTDLogo" />
            เข้าระบบ Thaiteledentistry
          </b-dropdown-item> -->
          <b-dropdown-item href="/logout">
            <i class="fas fa-sign-out-alt"></i>
            ออกจากระบบ
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>
    <b-popover
      id="todoPopover"
      ref="todoPopover"
      title="เตือนนัดหมายวันพรุ่งนี้"
      target="todoNotification"
      triggers="hover"
      placement="bottomleft"
    >
      <div>
        <div>
          <b-card
            class="mb-1 pointer"
            body-class="p-2"
            :border-variant="stsCount[1] === 0 ? 'success' : 'warning'"
            @click="routeQueueList('status')"
          >
            <b-row>
              <b-col cols="2" class="text-center"
                ><i
                  class="fas fa-phone fa-2x pt-2"
                  :class="stsCount[1] === 0 ? 'text-success' : 'text-warning'"
                ></i>
              </b-col>
              <b-col cols="" class="pl-2 text-center">
                <span v-if="stsCount[1] != 0"
                  >โทรยืนยันนัดหมายอีก {{ stsCount[1] }} ราย</span
                >
                <span v-else>ยืนยันนัดหมายทั้งหมดแล้ว</span>
                <b-progress
                  class="mt-2"
                  :max="stsCount.sum == null ? 1 : stsCount.sum"
                >
                  <b-progress-bar
                    :value="stsCount.sum == null ? 1 : stsCount[2]"
                    variant="success"
                    :animated="true"
                  ></b-progress-bar>
                  <b-progress-bar
                    :value="stsCount.sum - stsCount[1] - stsCount[2]"
                    variant="secondary"
                    :animated="true"
                  ></b-progress-bar>
                  <b-progress-bar
                    :value="stsCount[1]"
                    variant="warning"
                    :animated="true"
                    show-value
                  ></b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
          </b-card>
          <b-card
            class="mb-0 pointer"
            body-class="p-2"
            :border-variant="rmdCount.inc === 0 ? 'success' : 'warning'"
            @click="routeQueueList('reminder')"
          >
            <b-row>
              <b-col cols="2" class="text-center"
                ><i
                  class="fas fa-tooth fa-2x pt-2"
                  :class="rmdCount.inc === 0 ? 'text-success' : 'text-warning'"
                ></i>
              </b-col>
              <b-col cols="" class="pl-2 text-center">
                <span v-if="rmdCount.inc != 0"
                  >มีรายการแจ้งเตือน {{ rmdCount.inc }} ราย</span
                >
                <span v-else>ไม่มีรายการแจ้งเตือน</span>
                <b-progress
                  class="mt-2"
                  :max="rmdCount.sum == null ? 1 : rmdCount.sum"
                >
                  <b-progress-bar
                    :value="
                      rmdCount.sum == null ? 1 : rmdCount.sum - rmdCount.inc
                    "
                    variant="success"
                    :animated="true"
                  ></b-progress-bar>
                  <b-progress-bar
                    :value="rmdCount.inc"
                    variant="warning"
                    :animated="true"
                    show-value
                  ></b-progress-bar>
                </b-progress> </b-col
            ></b-row>
          </b-card>
        </div>
      </div>
    </b-popover>
    <UserProfileModal ref="userProfileModal" />
  </nav>
</template>

<script>
import { eventBus } from "@/main";
import { mapGetters, mapActions } from "vuex";
import UserProfileModal from "@/components/modal/UserProfile";
import get from "lodash/get";
import moment from "moment";
import Logo from "@/components/Logo";
import MorFunDeeLogo from "../../assets/img/morfundee-logo.png";
import TTDLogo from "../../assets/img/thai-teledentistry-logo.png";
import CopyToClipboard from "@/components/CopyToClipboard";

export default {
  name: "Navbar",
  components: {
    UserProfileModal,
    Logo,
    CopyToClipboard,
  },
  data() {
    return {
      profileInfo: {},
      tomorrow: moment().add(1, "days").format("YYYY-MM-DD"),
      soundUnlocked: false,
      volume: 0.6,
      MorFunDeeLogo,
      TTDLogo,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      authorization: "moduleUser/getAuthorization",
      userInfo: "moduleUser/getUserInfo",
      viewFile: "moduleContent/viewFile",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getBranchInfo: "moduleUser/getBranchInfo",
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getTomorrowAppointmentList:
        "moduleAppointment/getTomorrowAppointmentList",
      getReminderList: "moduleMaster/getReminderList",
      getAccessToken: "moduleAuth/getAccessToken",
    }),
    branchName() {
      return get(this.getBranchInfo, "name") || "";
    },
    clinicName() {
      return get(this.getBranchInfo, "clinic.name") || "";
    },
    // logoUrl() {
    //   return get(this.getBranchInfo, "clinic.logoUrl") || "";
    // },
    abbreviation() {
      if (
        this.profileInfo &&
        (this.profileInfo.firstNameEn || this.profileInfo.lastNameEn)
      ) {
        var firstName = "";
        var lastName = "";
        if (this.profileInfo.firstNameEn) {
          firstName = this.profileInfo.firstNameEn.charAt(0);
        }
        if (this.profileInfo.lastNameEn) {
          lastName = this.profileInfo.lastNameEn.charAt(0);
        }
        return `${firstName}${lastName}`;
      } else {
        return "";
      }
    },
    isDoctor() {
      return this.authorization.authId === 2;
    },
    onQueueRoute() {
      return (
        this.$route.name === "QueueManage" || this.$route.name === "QueueList"
      );
    },
    onDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
    onDoctorsTxQRoute() {
      return this.$route.name === "DoctorsTreatingQueue";
    },
    stsCount() {
      return this.statusCount();
    },
    rmdCount() {
      return this.reminderCount();
    },
    todoNumber() {
      return this.stsCount[1] + this.rmdCount.inc || 0;
    },
    logoUrl() {
      if (this.getBranchInfo?.clinic?.logo) {
        return this.viewFile(this.getBranchInfo.clinic.logo);
      }
      return "";
    },
    isStaging() {
      return ["staging", "dev"].includes(process.env.VUE_APP_MODE);
    },
    accessToken() {
      return localStorage.getItem("access_token");
    },
  },
  methods: {
    ...mapActions({
      fetchAllConfig: "moduleMaster/fetchAllConfig",
    }),
    init() {
      this.fetchAllConfig();
      eventBus.$on("updateProfile", userInfo => {
        this.profileInfo = userInfo || {};
      });
    },
    openCustomerModal() {
      eventBus.$emit("openCustomerModal");
    },
    openSearchCustomerModal() {
      eventBus.$emit("openSearchCustomerModal");
    },
    openUserProfileModal() {
      this.$refs.userProfileModal.show();
    },

    async routeQueueList(arg) {
      if (this.$route.name !== "QueueList") {
        await this.$router.push({ path: "/queuelist" });
      }
      eventBus.$emit("todoClick", arg);
    },
    statusCount() {
      let status = this.getTomorrowAppointmentList.reduce((acc, apt) => {
        if (typeof acc[apt.extendedProps.statusId] == "undefined") {
          acc[apt.extendedProps.statusId] = 1;
        } else {
          acc[apt.extendedProps.statusId] += 1;
        }
        !acc.sum ? (acc.sum = 1) : (acc.sum += 1);
        return acc;
      }, {});
      if (!status[1]) {
        status[1] = 0;
      }
      return status;
    },
    reminderCount() {
      let reminder = this.getTomorrowAppointmentList.reduce((acc, apt) => {
        if (typeof acc[apt.extendedProps.rmdId] == "undefined") {
          acc[apt.extendedProps.rmdId] = 1;
        } else {
          acc[apt.extendedProps.rmdId] += 1;
        }
        if (apt.extendedProps.rmdId != null) {
          !acc.sum ? (acc.sum = 1) : (acc.sum += 1);
        }
        return acc;
      }, {});
      let incomplete = 0;
      for (let key in reminder) {
        if (key > 0 && key <= 10) {
          incomplete += reminder[key];
        }
      }
      reminder.inc = incomplete;
      return reminder;
    },
    findStatusObj(statusId) {
      return this.getAppointmentStatusList.find(function (item) {
        return item["code"] == statusId;
      });
    },
    findReminderObj(rmdId) {
      return this.getReminderList.find(function (item) {
        return item["val"] == rmdId;
      });
    },
    emitVolume(vol) {
      eventBus.$emit("adjustVolume", vol);
    },
  },
  mounted() {
    this.profileInfo = this.userInfo || {};
    eventBus.$on("soundUnlocked", e => {
      this.soundUnlocked = e;
    });
    this.volume = parseFloat(localStorage.getItem("volume") || "0.6");
  },
  destroyed() {},
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.logo {
  max-height: 30px;
  width: auto;
}
</style>
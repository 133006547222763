<template>
  <div
    class="loading position-fixed tl-0 w-100 h-100 z-top"
    style="background-color: rgba(255, 255, 255, 0.5)"
    @click.stop.prevent="stopProp"
  >
    <div class="position-absolute d-flex tl-0 w-100 h-100">
      <div class="m-auto text-center">
        <div style="width: 10rem; height: 10rem" role="status">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 160 160"
          >
            <g class="cls-2">
              <path
                class="cls-3 path"
                d="M107.17,97.94c.82-2.29,2.01-6.61,1.22-11.92-.36-2.41-.86-5.75-3.36-8.86-4.14-5.14-10.56-5.67-12.22-5.81-6.97-.58-12.05,2.85-13.52,3.92,2.26,8.72,2.47,10.67,2.21,10.75-.61,.19-2.04-10.37-9.47-14.06-6.04-2.99-12.52-.27-13.14,0-7.09,3.1-9.43,10.44-10.39,13.45-1.6,5.01-1.4,10.01-1.22,14.67,.06,1.5,.2,2.89,.47,5.67,.9,9.13,2,14.96,2.58,17.86,1.7,8.38,2.73,9.93,3.67,11,.71,.81,4.05,4.64,8.86,4.28,5.26-.4,8.08-5.48,8.25-5.81,.12-.23,.21-.44,.39-.86,1.71-3.98,1.8-6.48,2.61-10.03,.96-4.22,1.78-7.78,4.08-8.45,1.13-.33,2.2,.16,2.69,.4,3.5,1.68,3.15,6.15,6.11,13.6,2.09,5.26,3.58,9.33,7.16,10.53,1.79,.6,4.23,.54,6.13-.66,1.37-.87,2.19-2.16,2.96-4.05,1.64-4.07,1.74-7.76,2.39-16.66,.1-1.3,.4-4,2.33-6.29,.14-.16,.28-.3,.42-.43,2.38-2.21,5.68-2.14,7.95-2.14,3.38,0,13.82-.02,19.25-1.83,7.44-2.48,13.25-10.91,14.36-19.56,1.88-14.6-10.43-23.73-11-24.14-7.17-5.13-14.85-5.03-17.42-4.89-.39-3.94-1.72-11.85-7.33-19.56-8.37-11.49-20.43-14.55-24.75-15.59-3.75-.89-13.77-3.14-25.36,1.22-13.36,5.02-19.86,15.44-21.7,18.64-3.3,.28-10.74,1.36-18.03,6.72-2.06,1.51-10.65,8.18-13.45,20.47-.73,3.21-3.38,14.88,3.97,25.36,5.23,7.45,12.93,10.15,16.2,11.31,5.33,1.88,10.05,2.18,13.14,2.14"
              />
            </g>
          </svg>

          <!-- <span class="">Loading...</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  components: {},
  data() {
    return {
      tips: ["test"],
    };
  },
  computed: {
    displayTip() {
      return this.tips[0];
    },
  },
  methods: {
    stopProp(e) {
      return;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.cls-1,
.cls-3 {
  fill: none;
}
.cls-2 {
  clip-path: url(#clip-path);
}
.cls-3 {
  stroke: #007bff;
  stroke-width: 10px;
  stroke-dasharray: 580;
  stroke-dashoffset: 580;
  animation: dash 4s ease-in-out alternate infinite;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>


<template>
  <div class="home">
    
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {

    }
  },
  create() {

  },
  computed: {

  },
  methods: {
    
  },
  mounted() {

  },
}
</script>

import axios from "axios";
import { eventBus } from "@/main";

const moduleTTD = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async createActiveCase({ commit, dispatch }, payload) {
      return await axios
        .post(
          `/api/thai-tele/active-case/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.data
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  getters: {},
};
export default moduleTTD;

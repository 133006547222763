import axios from "axios";
import { eventBus } from "@/main";

const moduleMaster = {
  namespaced: true,
  state: {
    genderList: [],
    titleNameList: [],
    titleNameEngList: [],
    personalTypeList: [],
    reqExtDocList: [],
    doctorColorList: [],
    userTypeList: [],
    drSpecialList: [],
    branchAuthorityList: [],
    mouthAreaList: [],
    appointmentStatusList: [],
    appointmentDurationList: [],
    reminderList: [],
    toothList: [],
    toothSurfaceList: [],
    bankList: [],
    appVersion: {},
    ads: []
  },
  mutations: {
    setGenderList(state, data) {
      state.genderList = data;
    },
    setTitleNameList(state, data) {
      state.titleNameList = data;
    },
    setTitleNameEngList(state, data) {
      state.titleNameEngList = data;
    },
    setPersonalTypeList(state, data) {
      state.personalTypeList = data;
    },
    setReqExtDocList(state, data) {
      state.reqExtDocList = data;
    },
    setDoctorColorList(state, data) {
      state.doctorColorList = data;
    },
    setUserTypeList(state, data) {
      state.userTypeList = data;
    },
    setDrSpecialList(state, data) {
      state.drSpecialList = data;
    },
    setBranchAuthorityList(state, data) {
      state.branchAuthorityList = data;
    },
    setMouthAreaList(state, data) {
      state.mouthAreaList = data;
    },
    setToothList(state, data) {
      state.toothList = data;
    },
    setToothSurfaceList(state, data) {
      state.toothSurfaceList = data;
    },
    setAppointmentStatusList(state, data) {
      state.appointmentStatusList = data;
    },
    setAppointmentDurationList(state, data) {
      state.appointmentDurationList = data;
    },
    setReminderList(state, data) {
      state.reminderList = data;
    },
    setBankList(state, data) {
      state.bankList = data;
    },
    setAppVersion(state, data) {
      state.appVersion = data;
    },
    setAds(state, data) {
      state.ads = data;
    },
    setAllConfig(state, data) {
      state.genderList = data.filter(i => i.configName === "gender");
      state.titleNameList = data.filter(i => i.configName === "title_name");
      state.titleNameEngList = data.filter(
        i => i.configName === "title_name_eng"
      );
      state.personalTypeList = [{ value: "", label: "เลือก" }].concat(
        data.filter(i => i.configName === "personal_type")
      );
      state.reqExtDocList = data.filter(i => i.configName === "req_ext_doc");
      state.doctorColorList = data.filter(
        i => i.configName === "dr_member_appt_color"
      );
      state.userTypeList = data.filter(i => i.configName === "user_type");
      state.drSpecialList = data.filter(i => i.configName === "dr_spcialty");
      state.branchAuthorityList = data.filter(
        i => i.configName === "branch_authority"
      );
      state.mouthAreaList = data.filter(i => i.configName === "mouth_area");
      state.appointmentStatusList = data.filter(
        i => i.configName === "appt_status"
      );
      state.appointmentDurationList = data.filter(
        i => i.configName === "time_duration"
      );
      state.reminderList = data.filter(i => i.configName === "appt_reminder");
      state.ads = data.filter(i => i.configName === "ads");
    }
  },
  actions: {
    fetchGenderList({ commit, getters }) {
      return axios
        .get(`/api/master/configValue/gender`)
        .then(res => {
          // return axios.get("/api/master/gender").then(res => {
          commit("setGenderList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchTitleNameList({ commit, getters }) {
      return axios
        .get(`/api/master/configValue/titleName`)
        .then(res => {
          // return axios.get('/api/master/titleName').then(res => {
          commit("setTitleNameList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchTitleNameEngList({ commit, getters }) {
      return axios
        .get(`/api/master/configValue/titleNameEng`)
        .then(res => {
          commit("setTitleNameEngList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchPersonalTypeList({ commit, getters }) {
      return axios
        .get(`/api/master/configValue/personalType`)
        .then(res => {
          commit("setPersonalTypeList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchReqExtDocList({ commit, getters }) {
      return axios
        .get(`/api/master/configValue/reqExtDoc`)
        .then(res => {
          commit("setReqExtDocList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchDoctorColorList({ commit }) {
      return axios
        .get(`/api/master/configValue/doctorColor`)
        .then(res => {
          commit("setDoctorColorList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchUserTypeList({ commit, getters }, reload) {
      return axios
        .get(`/api/master/configValue/userType`)
        .then(res => {
          commit("setUserTypeList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchDrSpecialList({ commit }) {
      return axios
        .get(`/api/master/configValue/drSpecial`)
        .then(res => {
          commit("setDrSpecialList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchBranchAuthorityList({ commit }) {
      return axios
        .get(
          `/api/master/configValue/branchAuthority`
        )
        .then(res => {
          commit("setBranchAuthorityList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchMouthArea({ commit }) {
      return axios
        .get(`/api/master/configValue/mouthArea`)
        .then(res => {
          commit("setMouthAreaList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchToothList({ commit }) {
      return axios
        .get(`/api/master/mTooth`)
        .then(res => {
          commit("setToothList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchToothSurfaceList({ commit }) {
      return axios
        .get(`/api/master/mToothSurface`)
        .then(res => {
          commit("setToothSurfaceList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAppointmentStatusList({ commit }) {
      return axios
        .get(
          `/api/master/configValue/appointmentStatus`
        )
        .then(res => {
          commit("setAppointmentStatusList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAppointmentDuration({ commit }) {
      return (
        axios
          // .get(`/api/master/appointmentDuration`)
          .get(
            `/api/master/configValue/appointmentDuration`
          )
          .then(res => {
            commit("setAppointmentDurationList", res.data);
            return res;
          })
          .catch(err => {
            /*eventBus.$emit("apiError", err);*/
            throw err;
          })
      );
    },
    fetchReminderList({ commit }) {
      return axios
        .get(`/api/master/configValue/all`)
        .then(res => {
          commit("setReminderList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAllConfig({ commit, dispatch }) {
      dispatch("fetchToothList");
      dispatch("fetchToothSurfaceList");
      return axios
        .get(`/api/master/configValue/all`)
        .then(res => {
          commit("setAllConfig", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    // fetchCoreConfig({ dispatch }) {
    //   dispatch("fetchGenderList");
    //   dispatch("fetchTitleNameList");
    //   dispatch("fetchTitleNameEngList");
    //   dispatch("fetchPersonalTypeList");
    //   dispatch("fetchReqExtDocList");
    //   dispatch("fetchDoctorColorList");
    //   dispatch("fetchUserTypeList");
    //   dispatch("fetchDrSpecialList");
    //   dispatch("fetchBranchAuthorityList");
    //   dispatch("fetchMouthArea");
    //   dispatch("fetchToothList");
    //   dispatch("fetchToothSurfaceList");
    //   dispatch("fetchAppointmentStatusList");
    //   dispatch("fetchAppointmentDuration");
    //   dispatch("fetchReminderList");
    // },

    //! รอ api bankList
    // fetchBankList({ commit }) {
    //   return axios
    //     .get(`/api/master/configValue/apptRmd`)
    //     .then(res => {
    //       commit("setBankList", res.data);
    //       return res;
    //     })
    //     .catch(err => {
    //       eventBus.$emit("apiError", err);
    //       throw err;
    //     });
    // },

    fetchAppVersion({ commit }) {
      // return axios
      //   .get("/api/master/appVersion")
      //   .then(res => {
      //     commit("setAppVersion", res.data);
      //     return res;
      //   })
      //   .catch(err => {
      //     eventBus.$emit("apiError", err);
      //     throw err;
      //   });
      return;
    },
    fetchAds({ commit }) {
      // return (
      //   axios
      //     .get(`/api/master/ads`)
      //     // .get(`/api/master/configValue/ads`)
      //     .then(res => {
      //       commit("setAds", res.data);
      //       return res;
      //     })
      //     .catch(err => {
      //       eventBus.$emit("apiError", err);
      //       throw err;
      //     })
      // );
      return null;
    }
  },
  getters: {
    findBy: () => (dataList, value, key, defaultVal) => {
      if (!value) {
        return defaultVal;
      }
      return (
        dataList.find(function(item) {
          return item[key] == value;
        }) || defaultVal
      );
    },
    getGenderList: state => {
      return state.genderList;
    },
    getGenderBy: (state, getters) => (value, key) => {
      return getters.findBy(state.genderList, value, key, {
        value: null,
        label: "-"
      });
    },
    getTitleNameList: state => {
      return state.titleNameList;
    },
    getTitleNameBy: (state, getters) => (value, key) => {
      return getters.findBy(state.titleNameList, value, key, {
        value: null,
        label: "-"
      });
    },
    getTitleNameEngList: state => {
      return state.titleNameEngList;
    },
    getTitleNameEngBy: (state, getters) => (value, key) => {
      return getters.findBy(state.titleNameEngList, value, key, {
        value: null,
        label: "-"
      });
    },
    getPersonalTypeList: state => {
      return state.personalTypeList;
    },
    getPersonalTypeBy: (state, getters) => (value, key) => {
      return getters.findBy(state.personalTypeList, value, key, {
        value: null,
        label: "-"
      });
    },
    getReqExtDocList: state => {
      return state.reqExtDocList;
    },
    getReqExtDocBy: (state, getters) => (value, key) => {
      return getters.findBy(state.reqExtDocList, value, key, {
        value: null,
        label: "-"
      });
    },
    getDoctorColorList: state => {
      return state.doctorColorList;
    },
    getDoctorColorBy: (state, getters) => (value, key) => {
      return getters.findBy(state.doctorColorList, value, key, {
        value: null,
        label: "-"
      });
    },
    getUserTypeList: state => {
      return state.userTypeList;
    },
    getUserTypeBy: (state, getters) => (value, key) => {
      return getters.findBy(state.userTypeList, value, key, {
        value: null,
        label: "-"
      });
    },
    getDrSpecialList: state => {
      return state.drSpecialList;
    },
    getDrSpecialBy: (state, getters) => (value, key) => {
      return getters.findBy(state.drSpecialList, value, key, {
        value: null,
        label: "-"
      });
    },
    getBranchAuthorityList: state => {
      return state.branchAuthorityList;
    },
    getBranchAuthorityBy: (state, getters) => (value, key) => {
      return getters.findBy(state.branchAuthorityList, value, key, {
        value: null,
        label: "-"
      });
    },
    getMouthAreaList: state => {
      return state.mouthAreaList;
    },
    getMouthAreaBy: (state, getters) => (value, key) => {
      return getters.findBy(state.mouthAreaList, value, key, {
        value: null,
        label: "-"
      });
    },
    getToothList: state => {
      return state.toothList;
    },
    getToothBy: (state, getters) => (value, key) => {
      return getters.findBy(state.toothList, value, key, {
        value: null,
        label: "-"
      });
    },
    getToothSurfaceList: state => {
      return state.toothSurfaceList;
    },
    getToothSurfaceBy: (state, getters) => (value, key) => {
      return getters.findBy(state.toothSurfaceList, value, key, {
        value: null,
        label: "-"
      });
    },
    getAppointmentStatusList: state => {
      return state.appointmentStatusList;
    },
    getAppointmentStatusBy: (state, getters) => (value, key) => {
      return getters.findBy(state.appointmentStatusList, value, key, {
        value: null,
        label: "-"
      });
    },
    getAppointmentDurationList: state => {
      return state.appointmentDurationList;
    },
    getReminderList: state => {
      return state.reminderList;
    },
    getReminderBy: (state, getters) => (value, key) => {
      return getters.findBy(state.reminderList, value, key, {
        value: null,
        label: "-"
      });
    },
    getBankList: state => {
      return state.bankList;
    },
    getAppVersion: state => {
      return state.appVersion;
    },
    getAds: state => {
      return state.ads;
    },
    getDateRangeShortcuts() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      return [
        {
          text: "วันนี้",
          onClick() {
            return [today, today];
          }
        },
        {
          text: "เมื่อวาน",
          onClick() {
            return [yesterday, yesterday];
          }
        },
        {
          text: "เดือนนี้",
          onClick() {
            return [new Date(today.getFullYear(), today.getMonth(), 1), today];
          }
        },
        {
          text: "เดือนที่แล้ว",
          onClick() {
            return [
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
              new Date(today.getFullYear(), today.getMonth(), 0)
            ];
          }
        },
        {
          text: "2เดือนก่อน",
          onClick() {
            return [
              new Date(today.getFullYear(), today.getMonth() - 2, 1),
              new Date(today.getFullYear(), today.getMonth() - 1, 0)
            ];
          }
        },
        {
          text: "3เดือนก่อน",
          onClick() {
            return [
              new Date(today.getFullYear(), today.getMonth() - 3, 1),
              new Date(today.getFullYear(), today.getMonth() - 2, 0)
            ];
          }
        }
        // {
        //   text: "ปีนี้",
        //   onClick() {
        //     return [new Date(today.getFullYear(), 0, 1), today];
        //   },
        // },
        // {
        //   text: "ปีที่แล้ว",
        //   onClick() {
        //     return [
        //       new Date(today.getFullYear() - 1, 0, 1),
        //       new Date(today.getFullYear() - 1, 11, 31),
        //     ];
        //   },
        // },
      ];
    }
  }
};
export default moduleMaster;

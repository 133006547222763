import axios from "axios";
import { eventBus } from "@/main";
import moment from "moment";

const modulePatient = {
  namespaced: true,
  state: {
    patientSearchList: [],
    maxPatient: null,

    patientInfo: {},
    recentPatients: [],
  },
  mutations: {
    setPatientSearchList(state, data) {
      state.patientSearchList = data ? data : [];
    },
    setMaxPatient(state, data) {
      state.maxPatient = data;
    },
    setPatientSearchScrollList(state, data) {
      state.patientSearchList = state.patientSearchList.concat(data);
    },
    setPatientInfo(state, data) {
      state.patientInfo = data ? data : {};
    },
    updateRecentPatients(state, data) {
      if (!data.id || data.id === 1) return;
      const getUniqueListBy = (data = [], key) => {
        const set = new Set();
        return data.reduce((arr, e) => {
          if (!set.has(e[key])) {
            set.add(e[key]);
            arr.push({ ...e });
          }
          return arr;
        }, []);
      };
      let list = [...state.recentPatients];
      list.unshift(data);
      state.recentPatients = getUniqueListBy(list, "id");
      state.recentPatients.splice(5);
    },
  },
  actions: {
    //--------- API ---------//
    fetchPatientById({ commit }, payload) {
      return axios
        .get(
          `/api/patient/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          // commit('setPatientInfo', res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createPatient({ commit }, payload) {
      return axios
        .post(
          `/api/patient/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.customer
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    checkDuplicate({ commit }, { clinicUrl, branchUrl, customer }) {
      return axios
        .get(`/api/patient/checkDuplicate/${clinicUrl}/${branchUrl}`, {
          params: {
            firstNameTh: customer?.firstNameTh,
            lastNameTh: customer?.lastNameTh,
            personalId: customer?.personalId,
            id: customer?.id,
          },
        })
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updatePatient({ commit }, payload) {
      return axios
        .put(
          `/api/patient/${payload.clinicUrl}/${payload.branchUrl}/${payload.customer.id}`,
          payload.customer
        )
        .then(res => {
          commit("setPatientInfo", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchPatientSearchList(
      { commit },
      {
        clinicUrl,
        branchUrl,
        offset = 0,
        limit = 20,
        keyword = "",
        isEveryBranch,
        branchId = this.state.moduleUser.branchInfo?.id,
      }
    ) {
      commit("setPatientSearchList", []);
      let url = `/api/patient/${clinicUrl}/${branchUrl}`;
      return axios
        .get(url, {
          params: {
            offset,
            limit,
            keyword,
            branchId: isEveryBranch ? null : branchId,
          },
        })
        .then(res => {
          commit("setPatientSearchList", res.data);
          commit("setMaxPatient", res.data.maxPatient);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchPatientSearchScrollList(
      { commit },
      {
        clinicUrl,
        branchUrl,
        offset = 0,
        limit = 20,
        keyword = "",
        isEveryBranch,
        branchId = this.state.moduleUser.branchInfo?.id,
      }
    ) {
      let searchUrl = `/api/patient/${clinicUrl}/${branchUrl}`;

      return axios
        .get(searchUrl, {
          params: {
            offset,
            limit,
            keyword,
            branchId: isEveryBranch ? null : branchId,
          },
        })
        .then(res => {
          commit("setPatientSearchScrollList", res.data);
          commit("setMaxPatient", res.data.maxPatient);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    addRecentPatient({ commit, getters }, payload) {
      commit("updateRecentPatients", getters.getRecentPatientFormat(payload));
    },
    setStatePatientSearchList({ commit }, data) {
      commit("setPatientSearchList", data);
    },
  },

  getters: {
    getPatientSearchList: state => {
      return state.patientSearchList;
    },
    getMaxPatient: state => {
      return state.maxPatient;
    },
    getPatientInfo: state => {
      return state.patientInfo;
    },
    getRecentPatients: state => {
      return state.recentPatients;
    },
    getRecentPatientFormat: (state, getter) => data => {
      return {
        id: data.id,
        fullName: `${data.firstNameTh} ${data.lastNameTh}`,
        timestamp: moment(),
      };
    },
  },
};
export default modulePatient;

<template>
  <b-modal
    id="UserProfileModal"
    ref="UserProfileModal"
    title="ข้อมูลผู้ใช้งาน"
    size="lg"
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col">
        <b-card class="m-0" no-body>
          <b-tabs card>
            <b-tab
              title="แก้ไขข้อมูลผู้ใช้"
              @click="onClickTab('edit-profile')"
              active
            >
              <b-card-text class="mx-5 mb-3">
                <update-profile-form
                  ref="updateProfileForm"
                ></update-profile-form>
              </b-card-text>
            </b-tab>
            <b-tab
              title="เปลี่ยนรหัสผ่าน"
              @click="onClickTab('change-password')"
            >
              <b-card-text class="mx-5 mb-3">
                <change-password-form
                  ref="changePasswordForm"
                ></change-password-form>
              </b-card-text>
            </b-tab>
            <template v-slot:tabs-end>
              <button class="close align-self-start ml-auto" @click="close">
                ×
              </button>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import UpdateProfileForm from "@/components/form/UpdateProfile";
import ChangePasswordForm from "@/components/form/ChangePassword";

export default {
  name: "UserProfile",
  components: {
    UpdateProfileForm,
    ChangePasswordForm,
  },
  data() {
    return {};
  },
  methods: {
    async show() {
      this.$bvModal.show("UserProfileModal");
      await this.$nextTick();
      this.$refs.updateProfileForm.resetForm();
    },
    close() {
      this.$bvModal.hide("UserProfileModal");
    },
    onClickTab(tabId) {
      if (tabId == "edit-profile") {
        this.$refs.updateProfileForm.resetForm();
      } else if (tabId == "change-password") {
        this.$refs.changePasswordForm.resetForm();
      }
    },
  },
};
</script>

<style>
#UserProfileModal .modal-body {
  padding: 0;
}
</style>
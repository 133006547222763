import {
  removeDuplicates,
  formatDateTime,
  generateDateTimeString,
  deepEqual,
} from "./utils";

export default {
  install(Vue) {
    Vue.prototype.$utils = {
      removeDuplicates,
      formatDateTime,
      generateDateTimeString,
      deepEqual,
    };
  },
};

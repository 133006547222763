import axios from "axios";
import { eventBus } from "@/main";

const moduleFhir = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async grantToken({ commit }, payload) {
      return axios
        .post(
          `/api/v1/fhir/grantToken/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            patientId: payload.patientId,
          }
        )
        .then(res => {
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
  },
  getters: {},
};
export default moduleFhir;

<template>
  <b-container>
    <h2 class="my-3">WebSocket Transaction Component</h2>

    <b-form-group label="WebSocket URL" label-for="websocket-url" class="mb-3">
      <b-input-group>
        <b-form-input
          id="websocket-url"
          v-model="editableWebsocketUrl"
          placeholder="Enter WebSocket URL"
          :state="isValidWebSocketUrl"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            @click="reconnectWebSocket"
            :disabled="!isValidWebSocketUrl"
          >
            {{ isConnected ? "Reconnect" : "Connect" }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback :state="isValidWebSocketUrl">
        Please enter a valid WebSocket URL (ws:// or wss://)
      </b-form-invalid-feedback>
    </b-form-group>

    <b-alert :variant="isConnected ? 'success' : 'danger'" show>
      {{
        isConnected ? "Connected to WebSocket" : "Disconnected from WebSocket"
      }}
    </b-alert>

    <b-button-group class="my-3">
      <b-button @click="selectTransaction('saleByCard')" variant="primary"
        >Sale By Card</b-button
      >
      <b-button @click="selectTransaction('saleByQR')" variant="primary"
        >Sale By QR</b-button
      >
      <b-button
        @click="selectTransaction('cancelTransaction')"
        variant="primary"
        >Cancel Transaction</b-button
      >
    </b-button-group>

    <b-form
      v-if="selectedTransaction"
      @submit.prevent="sendTransaction"
      class="my-3"
    >
      <h3>{{ getTransactionTitle(selectedTransaction) }}</h3>
      <b-form-group
        v-for="field in transactionTypes[selectedTransaction].fields"
        :key="`${selectedTransaction}-${field.FieldType}`"
        :label="field.FieldName"
        :label-for="field.FieldType"
      >
        <b-form-input
          :id="field.FieldType"
          v-model="dynamicFields[field.FieldType]"
          :placeholder="field.FieldName"
          required
          trim
        ></b-form-input>
      </b-form-group>
      <b-button
        type="submit"
        variant="success"
        :disabled="!isFormValid || isProcessing"
        >Send Transaction</b-button
      >
    </b-form>

    <b-card
      v-if="acknowledgment"
      header="Acknowledgment"
      bg-variant="info"
      text-variant="white"
      class="mt-3"
    >
      <pre>{{ JSON.stringify(acknowledgment, null, 2) }}</pre>
    </b-card>

    <b-card
      v-if="dataResponse"
      header="Transaction Response"
      bg-variant="light"
      class="mt-3"
    >
      <b-table-simple hover small caption-top responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>Field Name</b-th>
            <b-th>Data</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="field in dataResponse.FieldDatas" :key="field.FieldType">
            <b-td>{{ field.FieldName }}</b-td>
            <b-td>{{ field.Data }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-alert v-if="error" show variant="danger" class="mt-3">
      {{ error }}
    </b-alert>
  </b-container>
</template>

<script>
export default {
  props: {
    initialWebsocketUrl: {
      type: String,
      default: "ws://localhost:5000/ws/",
    },
  },
  data() {
    return {
      editableWebsocketUrl: this.initialWebsocketUrl,
      transactionTypes: {
        saleByCard: {
          transactionCode: "20",
          fields: [
            { FieldType: "A1", FieldName: "Invoice No." },
            { FieldType: "A3", FieldName: "VAT Refund Amount" },
            { FieldType: "40", FieldName: "Amount" },
          ],
        },
        saleByQR: {
          transactionCode: "QR",
          fields: [
            { FieldType: "A1", FieldName: "Invoice No." },
            { FieldType: "A3", FieldName: "VAT Refund Amount" },
            { FieldType: "40", FieldName: "Amount" },
          ],
        },
        cancelTransaction: {
          transactionCode: "26",
          fields: [
            { FieldType: "65", FieldName: "Invoice Number" },
            { FieldType: "01", FieldName: "Approval Code" },
            { FieldType: "F1", FieldName: "Card Type" },
          ],
        },
      },
      socket: null,
      isConnected: false,
      isProcessing: false,
      acknowledgment: null,
      dataResponse: null,
      error: null,
      selectedTransaction: null,
      dynamicFields: {},
    };
  },
  computed: {
    isValidWebSocketUrl() {
      const urlPattern = /^(ws|wss):\/\/\S+$/;
      return urlPattern.test(this.editableWebsocketUrl);
    },
    isFormValid() {
      if (!this.selectedTransaction) return false;
      return this.transactionTypes[this.selectedTransaction].fields.every(
        field =>
          this.dynamicFields[field.FieldType] &&
          this.dynamicFields[field.FieldType].trim() !== ""
      );
    },
  },
  created() {
    this.connectWebSocket();
    Object.values(this.transactionTypes).forEach(type => {
      type.fields.forEach(field => {
        this.$set(this.dynamicFields, field.FieldType, "");
      });
    });
  },
  beforeDestroy() {
    this.disconnectWebSocket();
  },
  methods: {
    connectWebSocket() {
      if (this.socket) {
        this.disconnectWebSocket();
      }

      this.socket = new WebSocket(this.editableWebsocketUrl);

      this.socket.onopen = () => {
        this.isConnected = true;
        console.log("WebSocket connected");
      };

      this.socket.onmessage = event => {
        const response = JSON.parse(event.data);
        this.handleResponse(response);
      };

      this.socket.onclose = () => {
        this.isConnected = false;
        console.log("WebSocket disconnected");
      };

      this.socket.onerror = error => {
        console.error("WebSocket error:", error);
        this.error = "Error connecting to WebSocket server";
        this.isConnected = false;
      };
    },
    disconnectWebSocket() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    },
    reconnectWebSocket() {
      this.connectWebSocket();
    },
    handleResponse(response) {
      if (response.AcknowledgeCode) {
        this.acknowledgment = response;
        this.startDataResponseTimer();
      } else {
        this.dataResponse = response;
        this.isProcessing = false;
      }
    },
    startDataResponseTimer() {
      setTimeout(() => {
        if (!this.dataResponse) {
          this.error = "Data response not received within 5 minutes.";
          this.isProcessing = false;
        }
      }, 5 * 60 * 1000); // 5 minutes
    },
    sendMessage(message) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify(message));
        this.startAcknowledgmentTimer();
      } else {
        this.error = "WebSocket is not connected";
        this.isProcessing = false;
      }
    },
    startAcknowledgmentTimer() {
      setTimeout(() => {
        if (!this.acknowledgment) {
          this.error = "Acknowledgment not received within 15 seconds.";
          this.isProcessing = false;
        }
      }, 15 * 1000); // 15 seconds
    },
    selectTransaction(type) {
      this.selectedTransaction = type;
      this.dynamicFields = {};
      this.acknowledgment = null;
      this.dataResponse = null;
      this.error = null;
      this.transactionTypes[type].fields.forEach(field => {
        this.$set(this.dynamicFields, field.FieldType, "");
      });
    },
    sendTransaction() {
      if (!this.selectedTransaction || !this.isFormValid) {
        this.error = "Invalid form data";
        return;
      }

      this.isProcessing = true;
      this.acknowledgment = null;
      this.dataResponse = null;
      this.error = null;

      const transactionData = this.transactionTypes[this.selectedTransaction];
      const message = {
        ReserveForFurfuresUse: "0000000000",
        PresentationHeader: {
          FormatVersion: "1",
          RequestResponseIndicator: "0",
          TransactionCode: transactionData.transactionCode,
          ResponseCode: "00",
          MoreDataIndicator: "0",
        },
        FieldDatas: transactionData.fields.map(field => ({
          FieldType: field.FieldType,
          FieldName: field.FieldName,
          Data: this.dynamicFields[field.FieldType].trim(),
        })),
      };
      this.sendMessage(message);
    },
    getTransactionTitle(type) {
      const titles = {
        saleByCard: "Sale By Card",
        saleByQR: "Sale By QR",
        cancelTransaction: "Cancel Transaction",
      };
      return titles[type] || "Unknown Transaction";
    },
  },
};
</script>
import axios from "axios";
import { eventBus } from "@/main";

const moduleCariesRiskAssessment = {
  namespaced: true,
  state: {
    questions: null,
    answers: null
  },
  mutations: {
    setQuestions(state, data) {
      state.questions = data;
    },
    setAnswers(state, data) {
      state.answers = data;
    }
  },
  actions: {
    createQuestions({ commit }, payload) {
      return axios
        .post(`/api/riskQuestions`, payload.data)
        .then(res => {
          commit("setQuestions", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchQuestions({ commit }, payload) {
      return axios
        .get(`/api/riskQuestions`)
        .then(res => {
          commit("setQuestions", res.data);
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchQuestionsById({ commit }, payload) {
      return axios
        .get(`/api/riskQuestions/${payload.id}`)
        .then(res => {
          commit("setQuestions", res.data);
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createAnswers({ commit }, payload) {
      return axios
        .post(`/api/riskEvaluation`, payload.data)
        .then(res => {
          commit("setAnswers", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAnswers({ commit }, payload) {
      return axios
        .get(`/api/riskEvaluation/${payload.patientID}`)
        .then(res => {
          commit("setAnswers", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateAnswers({ commit }, payload) {
      return axios
        .put(`/api/riskEvaluation`, payload.data)
        .then(res => {
          commit("setAnswers", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    }
  },
  getters: {
    getQuestions: state => {
      return state.questions;
    },
    getAnswers: state => {
      return state.answers;
    }
  }
};
export default moduleCariesRiskAssessment;

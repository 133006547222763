import axios from "axios";
const moduleContent = {
  namespaced: true,
  actions: {
    uploadFile({ commit }, file) {
      var formData = new FormData();
      formData.append("file", file);
      //   return axios
      //     .post(
      //       `/api/content/upload`,
      return axios
        .post(`/api/content/upload`, formData, {
          // .post("/api/content/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          return res;
        });
    }
  },
  getters: {
    viewFile: () => contentId => {
      if (contentId) {
        return `${process.env.VUE_APP_API_BASE_URL}/api/content/view/${contentId}`;
        // return `/api/content/view/${contentId}`;
      } else {
        return null;
      }
    },
    viewAvatar: () => (contentId, contentUrl) => {
      if (contentId) {
        return `${process.env.VUE_APP_API_BASE_URL}/api/content/view/${contentId}`;
        // return `/api/content/view/${contentId}`;
      } else if (contentUrl) {
        return contentUrl;
      } else {
        return null;
      }
    }
  }
};
export default moduleContent;

<template>
  <div
    class="hold-transition view-user-register"
    style="background: #e9ecef; overflow-x: hidden"
  >
    <div
      class="row justify-content-center align-items-center"
      style="height: 100vh"
    >
      <div class="row mx-3">
        <div class="col-12 mt-4">
          <div class="card">
            <div class="card-body login-card-body text-left">
              <template v-if="registerStatus == false">
                <b-form @submit.prevent="create">
                  <div class="row">
                    <div class="col-12">
                      <h3>สร้างบัญชีใหม่!</h3>
                      <p>
                        กรุณาลงข้อมูลตามความเป็นจริง
                        ข้อมูลจะถูกใช้ในการออกเอกสาร และรายงาน เช่น
                        การออกใบรับรองแพทย์
                      </p>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <label class="mt-1 mb-0" for="email">อีเมล์</label>
                        <input
                          type="text"
                          class="form-control required"
                          id="email"
                          :class="{
                            'is-invalid':
                              $v.registerForm.email.$error || isEmailDuplicate,
                          }"
                          v-model="registerForm.email"
                          @input="isEmailDuplicate = false"
                        />
                        <div
                          class="description error"
                          v-if="
                            $v.registerForm.email.$error || isEmailDuplicate
                          "
                        >
                          <span v-if="!$v.registerForm.email.required"
                            >*กรุณาใส่อีเมลล์</span
                          >
                          <span v-else-if="!$v.registerForm.email.email"
                            >กรุณาใส่อีเมลล์ให้ถูกต้อง ตัวอย่าง
                            example@email.com</span
                          >
                          <span v-else-if="isEmailDuplicate"
                            >อีเมลเคยลงทะเบียนแล้ว</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="mt-1 mb-0" for="password">รหัสผ่าน</label>
                        <input
                          type="password"
                          class="form-control required"
                          id="password"
                          :class="{
                            'is-invalid': $v.registerForm.password.$error,
                          }"
                          v-model="registerForm.password"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.password.$error"
                        >
                          <span v-if="!$v.registerForm.password.required"
                            >*กรุณาใส่รหัสผ่าน</span
                          >
                          <span v-else-if="!$v.registerForm.password.minLength"
                            >*รหัสผ่านต้องมีอย่างน้อย 8 ตัวขึ้นไป</span
                          >
                          <span
                            v-else-if="!$v.registerForm.password.passwordFormat"
                            >*รหัสผ่านต้องประกอบไปด้วยตัวเลข (0-9)
                            และตัวอักษรภาษาอังกฤษ (A-Z, a-z) อย่างน้อย 1
                            ตัว</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="mt-1 mb-0" for="confirmPassword"
                          >ยืนยันรหัสผ่าน</label
                        >
                        <input
                          type="password"
                          class="form-control required"
                          id="confirmPassword"
                          :class="{
                            'is-invalid':
                              $v.registerForm.confirmPassword.$error,
                          }"
                          v-model="registerForm.confirmPassword"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.confirmPassword.$error"
                        >
                          <span v-if="!$v.registerForm.confirmPassword.required"
                            >*กรุณาใส่รหัสผ่าน</span
                          >
                          <span
                            v-else-if="
                              !$v.registerForm.confirmPassword.sameAsPassword
                            "
                            >*รหัสผ่านไม่ตรงกับรหัสผ่าน</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <label class="mt-1 mb-0" for="firstNameTh"
                          >ชื่อภาษาไทย</label
                        >
                        <input
                          type="text"
                          class="form-control required"
                          id="firstNameTh"
                          :class="{
                            'is-invalid': $v.registerForm.firstNameTh.$error,
                          }"
                          v-model="registerForm.firstNameTh"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.firstNameTh.$error"
                        >
                          <span v-if="!$v.registerForm.firstNameTh.required"
                            >*กรุณาใส่ชื่อภาษาไทย</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="mt-1 mb-0" for="lastNameTh"
                          >นามสกุลภาษาไทย</label
                        >
                        <input
                          type="text"
                          class="form-control required"
                          id="lastNameTh"
                          :class="{
                            'is-invalid': $v.registerForm.lastNameTh.$error,
                          }"
                          v-model="registerForm.lastNameTh"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.lastNameTh.$error"
                        >
                          <span v-if="!$v.registerForm.lastNameTh.required"
                            >*กรุณาใส่นามสกุลภาษาไทย</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="mt-1 mb-0" for="firstNameEn"
                          >ชื่อภาษาอังกฤษ</label
                        >
                        <input
                          type="text"
                          class="form-control required"
                          id="firstNameEn"
                          :class="{
                            'is-invalid': $v.registerForm.firstNameEn.$error,
                          }"
                          v-model="registerForm.firstNameEn"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.firstNameEn.$error"
                        >
                          <span v-if="!$v.registerForm.firstNameEn.required"
                            >*กรุณาใส่ชื่อภาษาอังกฤษ</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="mt-1 mb-0" for="lastNameEn"
                          >นามสกุลภาษาอังกฤษ</label
                        >
                        <input
                          type="text"
                          class="form-control required"
                          id="lastNameEn"
                          :class="{
                            'is-invalid': $v.registerForm.lastNameEn.$error,
                          }"
                          v-model="registerForm.lastNameEn"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.lastNameEn.$error"
                        >
                          <span v-if="!$v.registerForm.lastNameEn.required"
                            >*กรุณาใส่นามสกุลภาษาอังกฤษ</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="mt-1 mb-0" for="mobile"
                          >เบอร์โทรศัพท์มือถือ</label
                        >
                        <b-input
                          type="text"
                          maxlength="10"
                          class="form-control required"
                          id="mobile"
                          :class="{
                            'is-invalid': $v.registerForm.mobile.$error,
                          }"
                          v-model="registerForm.mobile"
                        />
                        <div
                          class="description error"
                          v-if="$v.registerForm.mobile.$error"
                        >
                          <span v-if="!$v.registerForm.mobile.required"
                            >*กรุณาใส่เบอร์โทรศัพท์</span
                          >
                          <!-- <span v-else-if="!$v.registerForm.mobile.numeric"
                          >*เบอร์โทรศัพท์ต้องเป็นตัวเลข</span
                        > -->
                          <span v-else-if="!$v.registerForm.mobile.digits"
                            >*เบอร์โทรศัพท์ต้องมีตัวเลข 10 ตัว</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12">
                        <label class="mt-1 mb-0" for="userType"
                          >ประเภทผู้ใช้งาน</label
                        >
                        <select
                          class="form-control"
                          :class="{
                            'is-invalid': $v.registerForm.userTypeId.$error,
                          }"
                          v-model="registerForm.userTypeId"
                          @change="changeUserType()"
                        >
                          <template v-for="(val, index) in userTypeItemOptions">
                            <option v-bind:value="val.value" v-bind:key="index">
                              {{ val.label }}
                            </option>
                          </template>
                        </select>
                        <div
                          class="description error"
                          v-if="$v.registerForm.userTypeId.$error"
                        >
                          <span v-if="!$v.registerForm.userTypeId.notValue"
                            >*กรุณาเลือกประเภทผู้ใช้งาน</span
                          >
                        </div>
                      </div>
                    </div>
                    <template v-if="registerForm.userTypeId == '2'">
                      <div class="row">
                        <div class="col-12">
                          <label class="mt-1 mb-0" for="drLicenseNo"
                            >เลข ท.</label
                          >
                          <input
                            type="text"
                            maxlength="5"
                            class="form-control"
                            id="drLicenseNo"
                            :class="{
                              'is-invalid': $v.registerForm.drLicenseNo.$error,
                            }"
                            v-model="registerForm.drLicenseNo"
                          />
                          <div
                            class="description error"
                            v-if="$v.registerForm.drLicenseNo.$error"
                          >
                            <span v-if="!$v.registerForm.drLicenseNo.required"
                              >*กรุณาใส่เลข ท.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label
                            class="mt-1 mb-0"
                            for="selectedDrSpecialItemOptions"
                            >สาขาเฉพาะทาง</label
                          >
                          <multiselect
                            :class="{
                              'is-invalid':
                                $v.registerForm.drSpecialItems.$error,
                            }"
                            v-model="selectedDrSpecialItemOptions"
                            :options="drSpecialItemOptions"
                            :multiple="true"
                            track-by="val"
                            :custom-label="customLabel"
                            :close-on-select="false"
                            @input="selectDrSpecialItems()"
                          ></multiselect>
                          <div
                            class="description error"
                            v-if="$v.registerForm.drSpecialItems.$error"
                          >
                            <span
                              v-if="!$v.registerForm.drSpecialItems.required"
                              >*กรุณาเลือกสาขาเฉพาะทาง</span
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button
                        type="reset"
                        class="btn btn-danger btn-block btn-sm"
                      >
                        รีเซต
                      </button>
                    </div>
                    <div class="col-6">
                      <button
                        type="submit"
                        class="btn btn-primary btn-block btn-sm"
                      >
                        ยืนยัน
                      </button>
                    </div>
                  </div>
                </b-form>
              </template>
              <template v-if="registerStatus == true">
                <div class="row">
                  <div class="col">
                    <h2 class="text-warning text-center">
                      <i class="fas fa-exclamation-circle"></i>
                      กรุณาตรวจสอบอีเมล
                    </h2>
                    <p class="text-center">
                      เพื่อทำการยืนยันการลงทะเบียนของท่าน
                    </p>
                    <p class="text-center">หากไม่พบ กรุณาเช็ค junk mail</p>
                    <a href="/login" class="btn btn-block btn-primary"
                      >ลงชื่อเข้าใข้</a
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Loading from "@/components/Loading";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

const notValue = (number) =>
  helpers.withParams(
    { type: "notValue", value: number },
    (value) => !helpers.req(value) || value != number
  );

const digits = (number) =>
  helpers.withParams(
    { type: "digits", value: number },
    (value) => !helpers.req(value) || helpers.len(value) == number
  );

const passwordFormat = helpers.regex(
  "passwordFormat",
  /^(?=.*[A-Za-z])(?=.*\d)[\u0E00-\u0E7FA-Za-z\d@$!%*#?&]{1,}$/
);

export default {
  name: "RegisterViews",
  components: {
    Multiselect,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      registerForm: {
        firstNameTh: "",
        lastNameTh: "",
        firstNameEn: "",
        lastNameEn: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        userTypeId: "0",
        drLicenseNo: "",
        drSpecialItems: [],
      },
      // userTypeItemOptions: [{ value: "", label: "เลือก" }],
      selectedDrSpecialItemOptions: [],
      drSpecialItemOptions: [],
      registerStatus: false,

      isEmailDuplicate: false,
    };
  },
  validations: {
    registerForm: {
      firstNameTh: { required },
      lastNameTh: { required },
      firstNameEn: { required },
      lastNameEn: { required },
      email: { required, email },
      mobile: { required, numeric, digits: digits(10) },
      password: {
        required,
        minLength: minLength(8),
        passwordFormat: passwordFormat,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      userTypeId: { required, notValue: notValue("0") },
      drLicenseNo: {
        required: requiredIf((registerForm) => {
          return registerForm.userTypeId == "2";
        }),
      },
      drSpecialItems: {
        // required: requiredIf((registerForm) => {
        //   return registerForm.userTypeId == "2";
        // }),
      },
    },
  },

  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      userTypeItemOptions: "moduleMaster/getPersonalTypeList",
      drSpecialItemOptions: "moduleMaster/getDrSpecialList",
    }),
  },
  methods: {
    ...mapActions({
      registerUser: "moduleUser/registerUser",
    }),
    init() {},
    create() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      this.registerUser(this.registerForm)
        .then((res) => {
          if (res.status == 200) {
            this.registerStatus = true;
          }
        })
        .catch((err) => {
          if (
            err.response.status === 400 &&
            err.response.data === "The Email is existing"
          ) {
            this.isEmailDuplicate = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    customLabel(option) {
      return `${option.label}`;
    },
    selectDrSpecialItems() {
      let selectedOptions = [];
      this.selectedDrSpecialItemOptions.forEach((element) => {
        selectedOptions.push(element.value);
      });
      this.registerForm.drSpecialItems = selectedOptions;
    },
    changeUserType() {
      if (this.registerForm.userTypeId != "2") {
        this.registerForm.drLicenseNo = "";
        this.registerForm.drSpecialItems = [];
        this.selectedDrSpecialItemOptions = [];
      }
    },
  },
};
</script>

<style>
.logo-login-img {
  width: 40%;
}
</style>

import axios from "axios";
import { eventBus } from "@/main";

const moduleLab = {
  namespaced: true,
  state: {
    labExpenseList: [],
    labProductList: [],
    labCompanyList: []
  },
  mutations: {
    setLabExpenseList(state, data) {
      state.labExpenseList = data ? data : [];
    },
    setLabProductList(state, data) {
      state.labProductList = data ? data : [];
    },
    setLabCompanyList(state, data) {
      state.labCompanyList = data ? data : [];
    },

    setFavoriteLabProductList(state, data) {
      let index = state.labProductList.findIndex(function (event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.labProductList[index].favorite = !data.favorite;
      }
    }
  },
  actions: {
    // Lab expense list
    fetchLabExpenseList({ commit }, payload) {
      return (
        axios
          .get(`/api/lab/${payload.clinicUrl}/${payload.branchUrl}`, {
            params: payload.event
          })
          // .post(
          //   `/api/lab/search/${payload.clinicUrl}/${payload.branchUrl}`,
          //   payload.event
          // )
          .then(res => {
            commit("setLabExpenseList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    fetchLabExpenseForReport({ commit }, payload) {
      return axios
        .get(`/api/lab/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: payload.event
        })
        .then(res => {
          commit("setLabExpenseList", res.data);

          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createLabExpenseItem({ commit }, payload) {
      return axios
        .post(
          `/api/lab/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateLabExpenseItem({ commit }, payload) {
      const id = payload.event.id;
      delete payload.event.id;
      return axios
        .put(
          `/api/lab/${payload.clinicUrl}/${payload.branchUrl}/${id}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteLabExpenseItem({ commit }, payload) {
      return axios
        .delete(
          `/api/lab/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //Lab Product
    fetchLabProductList({ commit }, payload) {
      return axios
        .get(`/api/master/lab/${payload.clinicUrl}/${payload.branchUrl}`)
        .then(res => {
          commit("setLabProductList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchLabProductById({ commit }, payload) {
      return axios
        .get(
          `/api/master/lab/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    addLabProductFavorite({ commit }, payload) {
      return axios
        .post(
          `/api/master/labFav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFavoriteLabProductList", payload.event);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteLabProductFavorite({ commit }, payload) {
      return axios
        .delete(
          `/api/master/labFav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFavoriteLabProductList", payload.event);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteLabProduct({ commit }, payload) {
      return axios
        .delete(
          `/api/master/lab/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          // commit("setLabProductList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    createLabProduct({ commit }, payload) {
      return axios
        .post(
          `/api/master/lab/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateLabProduct({ commit }, payload) {
      let event = { ...payload.event };
      delete event.id;
      return axios
        .put(
          `/api/master/lab/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //Lab company
    fetchLabCompanyList({ commit }, payload) {
      return axios
        .get(`/api/master/companyLab/${payload.clinicUrl}/${payload.branchUrl}`)
        .then(res => {
          commit("setLabCompanyList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createLabCompany({ commit }, payload) {
      return axios
        .post(
          `/api/master/companyLab/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    editLabCompany({ commit }, payload) {
      // update ทั้ง list เลย
    },
    checkLabCompany({ commit }, payload) {
      // เช็คว่า company ถูก assign กับ Labproduct อยู่หรือไม่ ถ้าได้ assign ถึงจะลบได้
    },
    deleteLabCompanyitem({ commit }, payload) {
      return axios
        .delete(
          `/api/master/companyLab/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          // commit("deleteQueueAppointment", payload.event.id);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    }
  },
  getters: {
    getLabExpenseList: state => {
      return state.labExpenseList.filter(i => !i.cancelDt);
    },
    getLabProductList: state => {
      return state.labProductList;
    },
    getGroupedLabProdList: (state, getters) => {
      //favorite group
      const favGroup = [
        {
          category: "ผลิตภัณฑ์ใช้ประจำ",
          libs: []
        }
      ];
      getters.getLabProductList
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category

      const labProd = [
        { category: "ผลิตภัณฑ์แลป", libs: getters.getLabProductList }
      ];

      return favGroup.concat(labProd);
    },

    getLabCompanyList: state => {
      return state.labCompanyList;
    },

    getLabCompanyBy: (state, getters) => (value, key) => {
      return getters.findBy(state.labCompanyList, value, key, {}) || {};
    },
    findBy: () => (dataList, value, key, defaultVal) => {
      if (!value) {
        return defaultVal;
      }
      return dataList.find(function (item) {
        return item[key] == value;
      });
    }
  }
};
export default moduleLab;

<template>
  <b-alert
    v-if="!cookiesAccepted"
    show
    variant="dark"
    class="cookies-banner"
    dismissible
    @dismissed="cookiesAccepted = true"
  >
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-0">
        เราใช้คุกกี้เพื่อปรับปรุงประสบการณ์ของคุณบนเว็บไซต์ของเรา
        โดยการใช้เว็บไซต์ของเราแสดงว่าคุณยอมรับการใช้คุกกี้ของเรา
      </p>
      <div>
        <b-button size="sm" variant="success" @click="acceptCookies"
          >Accept</b-button
        >
        <!-- <b-button variant="danger" @click="declineCookies">Decline</b-button> -->
      </div>
    </div>
  </b-alert>
</template>

<script>
export default {
  data() {
    return {
      cookiesAccepted: false,
    };
  },
  created() {
    this.cookiesAccepted = localStorage.getItem("cookiesAccepted") === "true";
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookiesAccepted = true;
    },
    declineCookies() {
      localStorage.setItem("cookiesAccepted", "false");
      this.cookiesAccepted = true; // Hide the banner even if declined
    },
  },
};
</script>

<style scoped>
.cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
}
</style>
<template>
  <div class="wrapper">
    <Navbar></Navbar>
    <Sidebar></Sidebar>

    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/components/menubar/Navbar";
import Sidebar from "@/components/menubar/Sidebar";

import { eventBus } from "@/main";

export default {
  components: {
    Navbar,
    Sidebar,
  },
  data: () => ({
    treatmentAlert: new Audio(
      require("../../assets/sound/done-for-you-612.mp3")
    ),
    paymentAlert: new Audio(require("../../assets/sound/alert.mp3")),
    silence: new Audio(require("../../assets/sound/silence.mp3")),
    soundUnlocked: false,
    volume: 0.6,
    // treatmentAlert: new Audio(require("../../assets/sound/alert.mp3")),
  }),
  created() {
    document.body.addEventListener("click", this.unlockAudio);
    document.body.addEventListener("touchstart", this.unlockAudio);
    eventBus.$on("playAudio", this.playAudio);
    eventBus.$on("adjustVolume", this.adjustVolume);

    this.volume = parseFloat(localStorage.getItem("volume") || 0.6);
  },
  methods: {
    // browser จะไม่ใ้หใช้ auto-play นอกจากจะต้องมี interaction กับหน้า page ก่อนจึงต้องมี function unlockAudio ก่อนที่จะเริ่มใช้จริง
    async unlockAudio() {
      this.volume = parseFloat(localStorage.getItem("volume") || "0.6");
      this.treatmentAlert.volume = this.volume;
      this.paymentAlert.volume = this.volume;

      await this.$nextTick();
      if (this.$route.name === "QueueManage") {
        this.paymentAlert.play();
        this.paymentAlert.pause();
        this.paymentAlert.currentTime = 0;
      } else {
        this.treatmentAlert.play();
        this.treatmentAlert.pause();
        this.treatmentAlert.currentTime = 0;
      }
      // this.paymentAlert.play();
      // this.paymentAlert.pause();
      // this.adjustVolume();

      // this.silence.volume = 0;
      // await this.$nextTick();
      // if (playPromise !== undefined) {
      //   playPromise
      //     .then(() => {
      //       this.silence.currentTime = 0;
      //     })
      //     .catch((error) => {
      //       console.error(error);
      //     });
      // }

      console.log("-- Unlock Audio --");
      this.soundUnlocked = true;
      eventBus.$emit("soundUnlocked", this.soundUnlocked);
      document.body.removeEventListener("click", this.unlockAudio);
      document.body.removeEventListener("touchstart", this.unlockAudio);
    },
    async playAudio(e) {
      if (this.volume == 0) return; //ios ไม่สามารถ control volumn ได้ ถ้า vol=0 ไม่ต้อง play
      await this.$nextTick();
      console.log("--Play Audio--");
      if (e === "waitingForTreatment") {
        this.treatmentAlert.play();
      }
      if (e === "waitingForPayment") {
        this.paymentAlert.play();
      }
    },
    async adjustVolume(vol) {
      if (isNaN(vol)) return;
      this.volume = vol;
      this.treatmentAlert.volume = this.volume;
      this.paymentAlert.volume = this.volume;
      localStorage.setItem("volume", String(vol));
      if (this.volume == 0) return; //ios ไม่สามารถ control volumn ได้ ถ้า vol=0 ไม่ต้อง play

      await this.$nextTick();
      if (this.$route.name === "QueueManage") {
        this.paymentAlert.currentTime = 0;
        this.paymentAlert.play();
      } else {
        this.treatmentAlert.currentTime = 0;
        this.treatmentAlert.play();
      }
    },
  },
};
</script>
 
import axios from "axios";
import { eventBus } from "@/main";

const moduleBranch = {
  namespaced: true,
  state: {
    branchInfo: null,
    branchPath: "",

  },
  mutations: {
    setBranchInfo(state, data) {
    
      if (data && data.clinic) {
        state.branchInfo = data;
        state.branchPath = `${data.clinic.code}/${data.code}`;
      } else {
        state.branchInfo = null;
        state.branchPath = "";
      }
    },
  },
  actions: {
    updateBranchConfig({ commit }, payload) {
      return axios
        .put(
          `/api/branch/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.data
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          //eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchBranchByUrl({ commit }, payload) {
      return axios
        .get(`/api/branch/${payload.clinicUrl}/${payload.branchUrl}`)
        .then((res) => {
          commit("setBranchInfo", res.data);
          return res;
        })
        .catch((err) => {
          console.error(err);
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    checkBranchMember({ commit }, payload) {
      return axios
        .get(
          `/api/branchMember/unAuthorizeUser/${payload.clinicUrl}/${payload.branchUrl}/${payload.email}`
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          let { errorCode, errorMessage } = err.response.data;
          if (errorCode === 400 && errorMessage === "invalid email") {
            return null;
          }
          //   eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchBranchMember({ commit }, payload) {
      return axios
        .get(`/api/branchMember/${payload.clinicUrl}/${payload.branchUrl}`)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchBranchMemberIncludeDeleted({ commit }, payload) {
      return axios
        .get(`/api/branchMember/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: { activeMembersOnly: false },
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createBranchMember({ commit }, payload) {
      return axios
        .post(
          `/api/branchMember/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.member
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateBranchMember({ commit }, payload) {
      return axios
        .put(
          `/api/branchMember/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.member
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteBranchMember({ commit }, payload) {
      return axios
        .delete(
          `/api/branchMember/${payload.clinicUrl}/${payload.branchUrl}/${payload.uid}`
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createAndUpdateDooctorWorkingHours({ commit }, payload) {
      return axios
        .post(
          `/api/branchMember/doctorWorkingHour/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.doctorWorkingHours
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
  },
  getters: {
    getBranchInfo: (state) => {
      return state.branchInfo;
    },
    getClinicInfo: (state) => {
      if (state.branchInfo && state.branchInfo.clinic) {
        return state.branchInfo.clinic;
      } else {
        return null;
      }
    },
    getRoomList: (state) => {
      if (state.branchInfo && state.branchInfo.rooms) {
        return state.branchInfo.rooms;
      } else {
        return [];
      }
    },
    getRoomListBy: (state, getters) => (value, key) => {
      return getters.findBy(state.branchInfo.rooms, value, key, {
        value: null,
        label: "-",
      });
    },
    getAuthorization: (state) => {
      if (state.branchInfo && state.branchInfo.auth) {
        return state.branchInfo.auth;
      } else {
        return { authId: 0 };
      }
    },
    getBranchPath: (state) => {
      return state.branchPath;
    },
    isFileStorageExpired: (state) => {
      return state.isFileStorageExpired;
    },
  },
};
export default moduleBranch;

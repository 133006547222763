<template>
  <i class="far fa-clone" @click="copyContent"></i>
</template>

<script>
import { eventBus } from "@/main";

export default {
  name: "CopyToClipboard",
  props: ["text"],
  methods: {
    async copyContent() {
      try {
        await navigator.clipboard.writeText(this.text);
        eventBus.$emit("alertToast", {
          message: "บันทึกใส่คลิปบอร์ดสำเร็จ",
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to copy: ", err);
        eventBus.$emit("alertToast", {
          message: "บันทึกใส่คลิปบอร์ดไม่สำเร็จ",
          variant: "warning",
        });
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div></div>
</template>
<script>
export default {
  name: "DialogConfirm",
  data() {
    return {
      toastInstance: null,
    };
  },
  methods: {
    showAlertConfirm(
      title,
      text,
      icon,
      confirmMessage,
      cancelMessege,
      allowOutsideClick = true
    ) {
      return this.$swal.fire({
        title: title,
        text: text,
        icon: icon || "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmMessage || "ตกลง",
        cancelButtonText: cancelMessege || "ยกเลิก",
        reverseButtons: true,
        allowOutsideClick: allowOutsideClick,
      });
    },
    show3ButtonsConfirm({
      title,
      text,
      icon,
      confirmMessage,
      denyMessage,
      cancelMessege,
      confirmButtonColor,
      cancelButtonColor,
      denyButtonColor,
      allowOutsideClick = true,
      footer,
    }) {
      return this.$swal.fire({
        title: title,
        text: text,
        icon: icon || "warning",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor, //"#3085d6",
        cancelButtonColor, //"#d33",
        denyButtonColor,

        confirmButtonText: confirmMessage || "ตกลง",
        denyButtonText: denyMessage || "ปฏิเสธ",
        cancelButtonText: cancelMessege || "ยกเลิก",
        reverseButtons: true,
        allowOutsideClick: allowOutsideClick,
        footer: footer || "",
      });
    },
    showAlertInfo(title, text, icon) {
      return this.$swal.fire({
        title: title,
        text: text,
        icon: icon || "info",
      });
    },
    showTimeoutSession() {
      return this.$swal
        .fire({
          title: "เซสชั่นหมดอายุ",
          text: "กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "ตกลง",
        })
        .then(result => {
          this.$router.push({
            name: "Login",
            query: {
              redirect: this.$route.query.redirect
                ? this.$route.query.redirect
                : this.$route.path,
            },
          });
        });
    },
    showToast(variant, textDetail, noAutoHide = false) {
      let textTitle = "";
      if (variant == "warning") {
        textTitle = "คำเตือน";
      } else if (variant == "success") {
        textTitle = "สำเร็จ";
      } else if (variant == "info") {
        textTitle = "ข้อความแจ้ง";
      } else if (variant == "danger") {
        textTitle = "เกิดข้อผิดพลาด";
      }
      return this.$root.$bvToast.toast(textDetail, {
        title: textTitle,
        variant: variant,
        solid: true,
        noAutoHide: noAutoHide,
        toaster: "b-toaster-bottom-left",
      });
    },
    showToastWithSpinner(
      variant = "info",
      textDetail = "Files Uploading...",
      noAutoHide = false
    ) {
      // Create a temporary div to hold the content with spinner
      const h = this.$createElement;
      const content = h("div", { class: "d-flex align-items-center" }, [
        h(
          "div",
          {
            class: "spinner-border text-primary mr-2",
            attrs: { role: "status" },
          },
          [h("span", { class: "sr-only" }, "Loading...")]
        ),
        h("span", textDetail),
      ]);

      return this.$root.$bvToast.toast(content, {
        id: "my-spinner-toast",
        title: "แจ้งข้อมูล",
        variant: "info",
        solid: true,
        noAutoHide: true,
        toaster: "b-toaster-bottom-left",
      });
    },
    hideToast() {
      this.$root.$bvToast.hide("my-spinner-toast");
    },
  },
};
</script>

<style>
</style>
import axios from "axios";
import { eventBus } from "@/main";
import {
  FILE_LIMIT_EXCEED,
  FILE_STORAGE_PLAN_EXPIRED,
  REDIIVIEW_BASE_URL,
} from "@/constants";

const moduleMediaFile = {
  namespaced: true,
  state: {
    fileList: [],
    fileTemplate: [],
    fileDetail: {},
    newFilesLength: 0,
  },
  mutations: {
    setFileList(state, data) {
      state.fileList = data;
    },
    concatFileList(state, data) {
      state.fileList = [...state.fileList, ...data];
    },
    setFileTemplate(state, data) {
      state.fileTemplate = data;
    },
    concatFileTemplate(state, data) {
      state.fileTemplate = [...state.fileTemplate, ...data];
    },
    setFileDetail(state, data) {
      state.fileDetail = data;
    },
    RESET_FILE_LIST(state) {
      state.fileList = [];
    },
    setNewFileLength(state, data) {
      state.newFilesLength = data;
    },
  },
  actions: {
    async createMediaFiles({ commit, dispatch }, payload) {
      try {
        const urls = await dispatch("generatePresingUrl", payload);
        payload.urls = urls.map(i => i.url);
        const res = await dispatch("uploadFilesToS3", payload);
        return res;
      } catch (err) {
        const msg = err.response?.data.errorMessage;
        if (msg === "file limit exceeded")
          eventBus.$emit("alertSwal", FILE_LIMIT_EXCEED);
        console.log(msg);
        if (msg === "file store plan expired.")
          eventBus.$emit("alertSwal", FILE_STORAGE_PLAN_EXPIRED);

        eventBus.$emit("apiError", err);
        throw err;
      }
    },
    async generatePresingUrl({ commit }, payload) {
      return await axios
        .post(
          `/api/mediaFile/getPresigned/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.data
        )
        .then(res => {
          return res.data;
        })
        .catch(err => {
          // eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async uploadFilesToS3({ commit }, { files, urls }) {
      const results = [];
      for (let i = 0; i < files.length; i++) {
        try {
          const result = await axios.put(urls[i], files[i], {
            transformRequest: (data, headers) => {
              delete headers["Authorization"];
              return data;
            },
            headers: {
              "Content-Type": files[i].type,
            },
          });
          results.push(result);
        } catch (err) {
          results.push(null); // or any other error handling mechanism
          eventBus.$emit("apiError", err);
          throw err;
        }
      }
      return results;
    },
    async fecthMediaFiles({ commit, getters }, payload) {
      try {
        const concat = payload.params.concat;
        delete payload.params.concat;

        const ephemeral = payload.params.ephemeral;
        delete payload.params.ephemeral;

        const res = await axios.get(
          `/api/mediaFile/${payload.clinicUrl}/${payload.branchUrl}`,
          { params: payload.params }
        );
        const data = getters.getMediaFileListFormat(res.data);
        if (ephemeral) return data;
        if (concat) {
          commit("concatFileList", data);
          return;
        }
        commit("setFileList", data);
        return data;
      } catch (err) {
        eventBus.$emit("apiError", err);
        throw err;
      }
    },
    async fecthMediaFilesTemplate({ commit, getters }, payload) {
      try {
        const concat = payload.params?.concat;
        delete payload.params?.concat;

        const ephemeral = payload.params?.ephemeral;
        delete payload.params?.ephemeral;

        const res = await axios.get(
          `/api/mediaFile/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: { category: "Template" },
          }
        );
        const data = getters.getMediaFileListFormat(res.data);
        if (ephemeral) return data;
        if (concat) {
          commit("concatFileTemplate", data);
          return;
        }
        commit("setFileTemplate", data);
        return data;
      } catch (err) {
        eventBus.$emit("apiError", err);
        throw err;
      }
    },
    async deleteMediaFile({ commit, state }, payload) {
      try {
        const res = await axios.delete(
          `/api/mediaFile/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        );
        if (res.status === 200) {
          const f = state.fileList.filter(i => {
            return payload.id !== i.id;
          });
          commit("setFileList", f);
          const t = state.fileTemplate.filter(i => {
            return payload.id !== i.id;
          });
          commit("setFileTemplate", t);
        }
        return res;
      } catch (err) {
        eventBus.$emit("apiError", err);
        throw err;
      }
    },
    async setNewFileLength({ commit, state }, payload) {
      commit("setNewFileLength", payload);
    },
    async addNewFileLength({ commit, state }, payload) {
      commit("setNewFileLength", payload);
    },
    async fetchRadiiViewUrl(context, { imageUrl }) {
      return await axios.post(
        REDIIVIEW_BASE_URL,
        { image_url: imageUrl },
        {
          withCredentials: false,
        }
      );
    },
  },
  getters: {
    getFileList: state => {
      return state.fileList;
    },
    getFileTemplate: state => {
      return state.fileTemplate;
    },

    getMediaFileListFormat:
      (state, getters, rootState, rootGetters) => data => {
        return data.map(i => {
          return {
            id: i.id,
            src: i.mediaURL,
            title: i.remark ? i.remark : i.category,
            category: i.category,
            mediaType: i.fileType === "application/pdf" ? "iframe" : null,
            remark: i.remark,
            creationDt: i.creationDt,
            treatmentId: i.treatmentId,
            fileSize: i.fileSize,
            fileType: i.fileType,
          };
        });
      },
  },
};
export default moduleMediaFile;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "admin-lte";
import "admin-lte/dist/css/adminlte.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fortawesome/fontawesome-free/css/all.css";

import device from "vue-device-detector-js"; //Doc: https://www.npmjs.com/package/vue-device-detector-js
Vue.use(device);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/theme/Base.scss";

// datetime + luxon
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
Vue.use(Datetime);
Vue.component("datetime", Datetime);
import { Settings } from "luxon";
Settings.defaultLocale = "th";

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// ---- autocomplete ----
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

Vue.config.productionTip = false;

Vue.component("PatientProfile", () => import("@/components/PatientProfile"));

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
Vue.component("date-picker", DatePicker);

import VueKonva from "vue-konva";
Vue.use(VueKonva);

import utilsPlugin from "./utils/utilsPlugin";
Vue.use(utilsPlugin);

export const eventBus = new Vue();

import axios from "axios";
import VueAxios from "vue-axios";
import "./registerServiceWorker";
Vue.use(VueAxios, axios);
const localBaseUrl = `http://${window.location.hostname}:5001`;
axios.defaults.baseURL =
  process.env.NODE_ENV === "local"
    ? localBaseUrl
    : process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.credentials = "include";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept-Language"] = "en-US";

axios.interceptors.request.use(
  req => {
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    if (token) {
      req.headers["Authorization"] = token;
    }
    return req;
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (
      originalConfig.url !== `/api/login` &&
      err.response &&
      (originalConfig.baseURL.includes(process.env.VUE_APP_API_BASE_URL) ||
        originalConfig.baseURL.includes(localBaseUrl))
    ) {
      const { response } = err;

      if (
        response.data?.errorMessage?.message === "jwt expired" ||
        response.data?.errorMessage?.message === "jwt must be provided" ||
        response.data?.errorMessage?.message === "invalid signature" ||
        response.data?.errorMessage?.message ===
          "Invalid request. Token is not in store"
      ) {
        const loginpath = window.location.pathname;
        if (loginpath === "/oauth2") return Promise.reject(err);

        router.push({ path: "/login", query: { from: loginpath } });
        return Promise.reject(err);
      }

      if (response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        await store.dispatch("moduleAuth/refreshToken");
        return axios(originalConfig);
      }
    }

    return Promise.reject(err);
  }
);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
